import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import FormInput from '../../components/FormInput/FormInput';
import { useAuth } from '../../context/authContext';
import { formatValuesForSelect } from '../../utils/formatValuesForSelect';
import { UploadFile } from '../../components/UploadFile/UploadFile'
import { HandleUpload } from '../../components/UploadFile/HandleUpload';
import { fetchCatSys, fetchOneCattery, postCattery, updateCattery } from '../../services/api';

import "./cattery.scss"

const Cattery = () => {

  const BASE_URL = process.env.REACT_APP_API_URL; //need only for UploadFile - latter maybe better to refactor

  const navigate = useNavigate();
  const auth = useAuth();
  const location = useLocation();
  const currentUserId = auth.currentUser.id;

  const catteryId = new URLSearchParams(location.search).get('id');

  const [isCreating, setIsCreating] = useState(true); // determine if we're creating a new entity or updating an existing one
  // const [isFilePath, setIsFilePath] = useState(false);

  const [values, setValues] = useState(
    {
      cattery_name: '',
      cattery_name_full: '',
      certificate_issued_at: null,
      certificate_num: null,
      certificate_issued_by: null,
      image_url: null,
      cat_systems_id: null,
      users_id: auth.currentUser.id,
    }
  );

  const [selectError, setSelectError] = useState({
    fileError: "",
    systemError: ""
  });

  const [currentSystem, setCurrentSystem] = useState('')
  // const [err, setError] = useState(null);
  const [catSystems, setCatSystems] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePa, setFilePa] = useState(null);


  useEffect(() => {
    const fetchData = async () => {
      // console.log('Cattery | useEffect | catteryId = ', catteryId)

      if (catteryId) {
        setIsCreating(false);
        try {
          const res = await fetchOneCattery(currentUserId, catteryId) //we made a request from front on this http address to bring us info from server
          setValues(res.data[0]);
        } catch (error) {
          // console.log(error);
          console.error('Error fetching Data', error);
          throw error;
        }
      } else {
        // Explicitly set isCreating to true if catteryId is null or undefined
        setIsCreating(true);
      }
    }
    fetchData();
  }, [currentUserId, catteryId]);

  // Check if catteryId is null or undefined and explicitly set isCreating accordingly
  useEffect(() => {
    if (!catteryId) {
      setIsCreating(true);  // If catteryId is null or undefined, we are creating a new cattery
    }
  }, [catteryId]);

  useEffect(() => {
    const fetchCatSystems = async () => {
      // console.log('currentUser', currentUser)
      try {
        const res = await fetchCatSys() //we made a request from front on this http address to bring us info from server
        const dataforSelect = formatValuesForSelect(res, 'name', 'abb');
        setCatSystems(dataforSelect); //better like this setCatSystems(dataforSelect) - without callback arraw function inside
        // console.log('catSystem', dataforSelect)
        if (!isCreating) {
          const filtered = dataforSelect.filter(sys => sys.value === values.cat_systems_id)
          setCurrentSystem(filtered);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchCatSystems()
  }, [values.cat_systems_id, isCreating])

  // console.log('isCreating = ', isCreating);
  
  const inputs = [
    {
      id: "cattery_name",
      name: "cattery_name",
      type: "text",
      placeholder: "Cattery name",
      errorMessage: "Please, write a cattery name, it should be 3-16 symbols",
      label: "Cattery name*",
      pattern: "^[A-Za-z0-9]{3,16}$",
      required: true,
      maxLength: 100 // limit to 100 characters
      // className:"joinInput"
    },
    {
      id: "cattery_name_full",
      name: "cattery_name_full",
      type: "text",
      placeholder: "Full cattery name",
      label: "Full cattery name",
      maxLength: 100, // limit to 100 characters
      // errorMessage: "",
      // pattern: "^[A-Za-z0-9]{3,16}$",
      required: false,
      // className:"joinInput"
    },
    {
      id: "certificate_issued_at",
      name: "certificate_issued_at",
      type: "date",
      placeholder: "Certificate issue date",
      // errorMessage: "",
      label: "Certificate issue date",
      // pattern: "^[A-Za-z0-9]{3,16}$",
      required: false,
      maxLength: 100 // limit to 100 characters
    },
    {
      id: 4,
      name: "certificate_num",
      type: "text",
      placeholder: "Certificate number",
      errorMessage: "",
      label: "Certificate number",
      required: false, //without birthdate I can't send date to mysql - error.
      maxLength: 100 // limit to 100 characters
      // className:"joinInput"
    },
    {
      id: 5,
      name: "certificate_issued_by",
      type: "text", // Change the type to 'textarea'
      placeholder: "Club/system issued a certificate",
      errorMessage: "Club/systme can't be empty",
      label: "Club/system issued a certificate*",
      required: true,
      maxLength: 100 // limit to 100 characters
    },
  ]

  //UPLOAD Cattery sertificate to the server
  async function uploadCattery() {
    console.log('uploadCattery evoked')
    let fileP = await HandleUpload(selectedFile, `${BASE_URL}/upload`, currentUserId, 'cattery');
    console.log("fileP = ", fileP)
    setFilePa(fileP);
    setValues((prev) => ({ ...prev, image_url: fileP.filePath }))
    // .then((res)=>{final()}).then(()=> {console.log("FINAL")})

    // return fileP;
    // console.log('values after SetValues put from Cattery.jsx', values)

    // console.log('FileP.filePath', fileP.filePath);
    // if (selectedFile) {
    //   try {
    //     const response = await axios.post(`http://localhost:8800/api/catteries/${catteryId}/upload`, fileP);
    //     console.log(response.data);
    //   } catch (error) {
    //     console.log('No file', error);
    //   }
    // }
  }

  const handleChangeFile = (file) => {
    setSelectError({ fileError: "" });
    setSelectedFile(file)
  }

  const final = async (filePa, values, catteryId) => {
    console.log('filePa from final', filePa);
    console.log('final | values from final', values);
    console.log('final | isCreating = ', isCreating);
    if (filePa) {
      try {
        if (isCreating) {
          console.log('isCreating')
          //creating a new entity
          const response = await postCattery(values)
          console.log('New cattery created:', response.data);
          navigate('/my')
        } else {
          console.log('isUpdating')
          //updating an existing entity
          await updateCattery(catteryId, values);
          // console.log('Cattery updated:', res.data);
          navigate('/my')
        }
      }
      catch (error) { console.log('Error submiting form:', error) }
    }
  }

  // useEffect(() => {
  //   // console.log('values from UseEffect from Cattery.jsx', values);
  //   if (filePa) final();
  // }, [filePa]);
  useEffect(() => {
    // console.log('values from UseEffect from Cattery.jsx', values);
    if (filePa) final(filePa, values, catteryId);
  }, [values]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (currentSystem) {
      // console.log('currentSystem true', currentSystem)
      if (selectedFile) {
        setSelectError({ fileError: "" });
        uploadCattery();
      } else {
        // console.log('no file')
        setSelectError({ fileError: "Upload a photo" });
      }
    } else setSelectError({ systemError: "Chose a system" });
  };

  const onChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  };

  const onChangeSystem = (selectedOption) => {
    setCurrentSystem(() => selectedOption);
    setValues((prev) => ({ ...prev, cat_systems_id: selectedOption.value }))
  }

  return (
    <div className="cattery">
      <form className="cattery-form" onSubmit={handleSubmit}>
        <h1>Cattery {values[0] && (values[0].cattery_name)}</h1>
        {values[0] && (
          inputs.map((input) => (<FormInput key={input.id} {...input} value={values[0][input.name] || ''} onChange={onChange} />))
        )}
        {/* it's rendered if we don't have any cattary in our values */}
        {values && !values[0] && (
          inputs.map((input) => (<FormInput key={input.id} {...input} value={values[input.name] || ''} onChange={onChange} />))
        )}

        <div className="cattery-selectInput">
          {/* <AsyncSelect loadOptions={loadOptions} onChange={handleSelectChange} value={selectedOption} defaultOptions /> */}
          <label className="selectInput__label">Your cattery system (on your certificate)*</label>
          <Select className="selectInput__input" options={catSystems} onChange={onChangeSystem} value={currentSystem} />
        </div>
        {selectError.systemError && <div className='messageError'>{selectError.systemError}</div>}
        <UploadFile selectedFile={selectedFile} onChangeFile={handleChangeFile} />
        {selectError.fileError && <div className='messageError'>{selectError.fileError}</div>}
        <button className='joinButton'>Send</button>
        <button type='button' className='button-light' onClick={() => navigate(`/my`)}>Cancel</button>
        {/* {err && <div className='errorMessage'> {err}</div>} */}
        {/* <span>Do you have an account? <Link to="/login">Login</Link></span> */}
      </form>
    </div>
  )

}

export default Cattery
