import React from 'react'
import "./pagination.scss";
import _ from 'lodash'; // underscore - a very popular labrary
import PropTypes from 'prop-types';

//<Pagination itemsCount={numberOfCats} pageSize={pageSize} onPageChange={handlePageChange} />

const Pagination = (props) => {
    const { itemsCount, pageSize, onPageChange, currentPage } = props;
    // console.log(currentPage);
    //[1, 2, 3] or [1 ... pagesCount].map()
    const pagesCount = Math.ceil(itemsCount / pageSize); // we get a number of 1, 2 or 3 or more, then we need to create array based on it
    // use npm i lodash here
    if (pagesCount === 1) { return null }
    const pages = _.range(1, pagesCount + 1);

    return (
        <nav>
            <div className="pagination-wrap">
                <ul className="pagination">
                    {pages.map(page =>
                        <button key={page} className={page === currentPage ? 'page-item active' : 'page-item'} onClick={() => onPageChange(page)}>
                            {/* <a className="page-link" onClick={() => onPageChange(page)}>{page}</a> */}
                            {page}
                        </button>
                    )}
                    {/* <li className="page-item"><a className="page-link active">2</a></li> */}
                </ul>
            </div>
        </nav>
    );
}
// You can declare that a prop is a specific JS primitive.
Pagination.propTypes = {
    itemsCount: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired
}

export default Pagination;  