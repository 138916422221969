function formatDateTime(isoString) {
    const date = new Date(isoString);

    // Extract the date parts
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
    const day = date.getDate().toString().padStart(2, '0');

    // Extract the time parts
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    // Combine the date and time
    const formattedDate = `${year}-${month}-${day}`;
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return `${formattedDate} ${formattedTime}`;
}

// Example usage:
// const isoString = '2023-02-05T22:00:00.000Z';
// const result = formatDateTime(isoString);
// console.log(result); // Output: "2023-02-05 22:00:00"

export default formatDateTime;