import React from 'react';
import './listGroup.scss';

const ListGroup = (props) => {
    const { numberOfCats, items, selectedFilter, onFilterSelect } = props;
    return (
        <div>
            <ul className="list-group">
                {items.map(item => (
                    <li
                        key={item}
                        className={item === selectedFilter ? 'list-group-item active' : 'list-group-item'}
                        onClick={() => onFilterSelect(item)}>
                        {item === 'all' ? (item + ` (` + (numberOfCats || '0') + `)`) : (item)}
                    </li>

                ))}
            </ul>
        </div>)
}

ListGroup.defaultProps = {
    selectedFilter: 'all'
    // textProperty: "name",
    // valueProperty: 0
}

export default ListGroup

// const ListGroup = (props) => {
//     const { numberOfCats, currentFilter, onFilterChange } = props;
//     return (
//         <div>
//             <ul className="list-group">
//                 <li className={currentFilter === 'all' ? 'list-group-item active' : 'list-group-item'} onClick={() => onFilterChange('all')}>All: {numberOfCats}</li>
//                 <li className={currentFilter === 'female' ? 'list-group-item active' : 'list-group-item'} onClick={() => onFilterChange('female')}>Female</li>
//                 <li className={currentFilter === 'male' ? 'list-group-item active' : 'list-group-item'} onClick={() => onFilterChange('male')}>Male</li>
//             </ul>
//         </div>)
// }

// export default ListGroup