// import { useState } from "react";
import http from './httpService';

export default function fetchCatColorForSelect() {

    return new Promise(async (resolve, reject) => {
        try {
            const resMainColor = await http.get(`/color/main`);
            const resGoldColor = await http.get(`/color/gold`);
            const resWhiteColor = await http.get(`/color/white`);
            const resTabbyColor = await http.get(`/color/tabby`);
            const resPointColor = await http.get(`/color/point`);
            const resEyeColor = await http.get(`/color/eye`);
            // const resTabbyColor = await http.get(`${config.tabbyColor}`);
            // const resPointColor = await http.get(`${config.pointColor}`);
            // const resEyeColor = await http.get(`${config.eyeColor}`);

            const arrayMainColor = resMainColor.data.map((item) => ({
                value: item.ems,
                label: `${item.ems} - ${item.name_eng}`
            }));
            const arrayGoldColor = resGoldColor.data.map((item) => ({
                value: item.ems,
                label: `${item.ems} - ${item.name_eng}`
            }));
            const arrayWhiteColor = resWhiteColor.data.map((item) => ({
                value: item.ems,
                label: `${item.ems} - ${item.name_eng}`
            }));
            const arrayTabbyColor = resTabbyColor.data.map((item) => ({
                value: item.ems,
                label: `${item.ems} - ${item.name_eng}`
            }));
            const arrayPointColor = resPointColor.data.map((item) => ({
                value: item.ems,
                label: `${item.ems} - ${item.name_eng}`
            }));
            const arrayEyeColor = resEyeColor.data.map((item) => ({
                value: item.ems,
                label: `${item.ems} - ${item.name_eng}`
            }));

            const emptyObject = { value: null, label: '...Select an option...' }
            const colorSelectObj = {
                arrayMainColor: [...arrayMainColor, emptyObject],
                arrayGoldColor: [...arrayGoldColor, emptyObject],
                arrayWhiteColor: [...arrayWhiteColor, emptyObject],
                arrayTabbyColor: [...arrayTabbyColor, emptyObject],
                arrayPointColor: [...arrayPointColor, emptyObject],
                arrayEyeColor: [...arrayEyeColor, emptyObject],
            };

            // console.log('obj2 from fetchCatColor',colorSelectObj)
            resolve(colorSelectObj);

        } catch (error) {
            console.log("Error fetcing cat color", error);
            reject(error);
        }

    })
}