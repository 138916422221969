import React from 'react';
import './dialogModelAdmin.scss'

//dialogOpenMetric
export const DialogModalAdmin = (props) => {
    return (
        <React.Fragment>
            {props.dialogOpenAdmin && (
                <dialog id="openPedigreeAdmin" className="admin__dialog" open={props.dialogOpenAdmin} onClose={props.onCloseDialogAdmin}>
                    <div className='admin__dialog_div'>
                        <div className="title-and-close">
                            {/* <h2>{props.title}</h2>
                            <p> {props.num}</p> */}
                            <form method="dialog">
                                <button className='profile-button'><span className='big'>X</span></button>
                            </form>
                        </div>
                        <figure className='dialogAdmin__img'>
                            {props.img && <img src={props.img} alt="Pedigree" />}
                        </figure>
                        <form method="dialog">
                            <button className='profile-button'>Close</button>
                        </form>
                    </div>
                </dialog>
            )}
        </React.Fragment>
    )
}