import React, { createContext, useEffect, useState } from 'react';
import { fetchOneCatAndColor } from '../services/api';
import formatDateFromUTC from '../utils/formatDateFromUTC';
import formatDateFromDB from '../utils/formatDateFromDB';
// import { useLocation } from 'react-router-dom';
// import calculateAge from '../../../backend/utils/calculateAge';
// import formatDateShort from '../../../backend/utils/formatDateShort';

const CatContext = createContext();

function CatContextProvider({ children, catId }) {
    // console.log('CatContextProvider | catId from props', catId)
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    // const location = useLocation();

    // let catId = location.pathname.split('/').pop(); // Derive catId from the URL dynamically
    // const catId = useMemo(() => location.pathname.split('/').pop(), [location.pathname]);

    useEffect(() => {
        const fetchCatData = async () => {
            // console.log('CatContextProvider | catId', catId);
            setLoading(true);
            try {
                const resDataOneCatAndColor = await fetchOneCatAndColor(catId);
                // console.log('resDataOneCatAndColor = ', resDataOneCatAndColor);
                let formattedBirthdate;
                let formattedBirthdateInput;
                // ----- Format dates from UTC to Output: "31 Dec 2018" ------ //
                if (resDataOneCatAndColor?.cat_birthdate) {
                    formattedBirthdate = formatDateFromUTC(resDataOneCatAndColor?.cat_birthdate);
                    // console.log("catContext | formattedBirthdate = ", formattedBirthdate);

                    // ----- Format dates from DB to Output: "2020-05-11" `${year}-${month}-${day}`; //yyyy-MM-dd - required format for input type='date'
                    formattedBirthdateInput = formatDateFromDB(resDataOneCatAndColor?.cat_birthdate);
                    // console.log("catContext | formattedBirthdateInput = ", formattedBirthdateInput)
                }

                // Create a new data object with formatted dates
                const newResDataOneCatAndColor = {
                    ...resDataOneCatAndColor,
                    cat_birthdate: formattedBirthdate,
                    cat_birthdate_input: formattedBirthdateInput,
                    // description: resDataOneCatAndColor.description || ""
                };

                setData(newResDataOneCatAndColor);
            } catch (err) {
                console.error('Error in catContext:', err);
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        if (catId) {
            fetchCatData();
        }
    }, [catId]);

    const catContextValue = { data, setData, loading, error }

    // const catContextValue = useMemo(
    //     () => ({ data, setData, loading, error }),
    //     [data, loading, error]
    // );
    // console.log('CatContext | catContextValue = ', catContextValue)
    return (
        <CatContext.Provider value={catContextValue}>
            {children}
        </CatContext.Provider>
    );
}

export { CatContext, CatContextProvider };

// Improvements Explained:
// Error Handling:
// Added error state to capture and provide feedback in case the data fetching fails.
// The try-catch block within the fetchSaleData function allows the component to gracefully handle errors.
// Loading State:
// Added a loading state to indicate when the data is being fetched. This is useful for showing loading indicators in the UI.
// Memoization:
// Wrapped the getCatIdFromCurrentUrl call in useMemo to avoid recalculating the cat ID unnecessarily. This helps with performance, especially if the URL path changes frequently or the component re-renders often.
// Similarly, useMemo is used to memoize the context value, ensuring that the context provider only updates its value when the relevant state variables (data, loading, error) change.
// Conditionally Fetching Data:
// The if (catId) check ensures that the fetch operation only occurs when a valid cat ID is present, preventing unnecessary API calls.
// Cleaner Code Organization:
// Simplified the getCatIdFromCurrentUrl function by directly returning the result, making it more readable.
// Consolidated the context value into a single contextValue object, which is cleaner and easier to manage.