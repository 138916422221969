import React, { useState } from 'react';
// import '../styles/global.scss';
import './catMiniCard.scss'

const CatMiniCard = ({ data, userId, onClickCat }) => {
   const [selectedId, setSelectedId] = useState(null);

   return (
      <div className="cat-mini-card">
         {data?.map((item) => {
            const isFemale = item.sex === 'female';
            const isSelected = item.id === selectedId;

            let cardClass = 'cat-mini-card__card';
            cardClass += isFemale ? ' cat-mini-card__card--female' : ' cat-mini-card__card--male';
            cardClass += isSelected ? ' cat-mini-card__card--selected' : '';

            return (
               <div
                  className={cardClass}
                  key={item.id}
                  onClick={() => {
                     onClickCat({ catId: item.id, userId: item.users_id });
                     setSelectedId(item.id);
                  }}
               >
                  <div className="cat-mini-card__id"><small>Id:</small> <div><small>{item.id}</small></div></div>
                  <img className="cat-mini-card__img " src={item.cat_ava_url} alt="cat" />
                  {/* <div className="cat-mini-card__content"> */}
                  <div className="cat-mini-card__item">
                     <b>{item.cat_name_cattery_prefix} {item.cat_name} {item.cat_name_suffix}</b>
                     <div className="cat-mini-card__breed"><small className="cat-mini-card__gray">breed:</small> <small> {item.breed}</small></div>
                     <div className="cat-mini-card__birthday"><em>{item.cat_birthdate}</em></div>
                     {/* </div> */}
                  </div>
                  {/* <div className="cat-mini-card__footer"> */}
                  <div className="cat-mini-card__ped"><small className="cat-mini-card__gray">ped № </small><small>{item.pedigree_num ? item.pedigree_num : 'no pedigree provided'}</small></div>
                  {item?.registered ? <div className="cat-mini-card__owned">Reg</div> : <div></div>}
                  {(item?.is_my_cat && item?.users_id !== userId) ? (<div className="cat-mini-card__owned">Owned</div>) : (<div></div>)}
                  <div className="cat-mini-card__colorems">{item.cat_color}</div>
                  <div className="cat-mini-card__ems">{item.ems}</div>
                  {/* </div> */}
               </div>
            );
         })}
      </div>
   );
};

export default CatMiniCard;






// import React, { useEffect, useRef, useState } from 'react'
// import '../styles/global.scss';

// const CatMiniCard = ({ data, userId, onClickCat }) => {
//     const [selectedId, setSelectedId] = useState(null);
//     const wrapperRef = useRef(null);
//     const [hoveredId, setHoveredId] = useState(null); // changed to hoveredId
//     // console.log('catMiniCard data = ', data)
//     // console.log('catMiniCard selectedId = ', selectedId)

//     const baseCardStyle = {
//         display: 'flex',
//         flexDirection: 'column',
//         // alignItems: 'center',
//         // justifyContent: 'space-between',
//         padding: '8px 10px',
//         marginBottom: '15px',
//         borderRadius: '8px',
//         border: '1px solid transparent',
//         boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
//         cursor: 'pointer',
//     };

//     const cardStyle = {
//         ...baseCardStyle,
//         backgroundColor: 'var(--male-blue)',
//         '@media (maxWidth: 768px)': {
//             padding: '4px 5px', // Reduce padding
//         }
//     };

//     const cardStyleFemale = {
//         ...baseCardStyle,
//         backgroundColor: 'var(--female-pink)',
//         transition: 'background-color 0.2s ease,box-shadow 0.2s ease'
//     };

//     const cardHoverStyle = {
//         ...baseCardStyle,
//         backgroundColor: 'var(--male-blue-dark)',  // Adjusted to a slightly different shade for demonstration
//         boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
//         transition: 'background-color 0.2s ease,box-shadow 0.2s ease'
//     };

//     const cardHoverStyleFemale = {
//         ...baseCardStyle,  // spread all original styles
//         backgroundColor: 'var(--female-pink-dark)',  // changed background color for hover effect
//         boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'  // deeper shadow for hover effect
//     };

//     const itemStyle = {
//         // width: '100%',
//         // flex: 2,
//         // border: '1px solid green',
//         marginRight: '4px'
//     };

//     const second = {
//         border: '1px solid blue',
//         display: 'flex',
//         flexDirection: 'row',
//         // alignItems: 'center',
//         justifyContent: 'space-between',
//         gap: '1rem',
//         marginLeft: '60px'
//     };

//     const first = {
//         display: 'flex',
//         flexDirection: 'row',
//         flex: '1 3',
//         // alignItems: 'stretch',
//         justifyContent: 'start',
//         gap: '1rem'
//     };

//     const emsStyle = {
//         ...itemStyle,
//         // flex: 0.5,
//         marginRight: 0,
//         backgroundColor: 'rgba(255, 255, 255, 0.5)',
//         padding: '2px 6px'
//     };

//     const imgStyle = {
//         width: '80px',
//         height: 'auto'
//     }
//     // const preLastItemStyle = {
//     //     ...itemStyle,
//     //     // flex: 0.5,
//     //     // marginRight: '4px'
//     // };

//     const idStyle = {
//         ...itemStyle,
//         color: '$main-color',
//         flex: '0 0 40px'/* don't grow, don't shrink, start at 25% of the container's size */
//         // flex: '0 2 50px',
//         // marginRight: '2px'
//     };

//     const chosenStyle = {
//         border: '1px solid rgb(145, 97, 106)'
//     };

//     useEffect(() => {
//         function handleClickOutside(event) {
//             if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
//                 setSelectedId(null);
//             }
//         }

//         document.addEventListener("mousedown", handleClickOutside);
//         return () => {
//             document.removeEventListener("mousedown", handleClickOutside);
//         };
//     }, [wrapperRef]);

//     return (
//         <div ref={wrapperRef} >
//             {data?.map((item) => {
//                 const isFemale = item.sex === 'female';
//                 const isSelected = item.id === selectedId;
//                 const isThisHovered = item.id === hoveredId; // check if this item is hovered

//                 let currentStyle = isFemale ? cardStyleFemale : cardStyle;
//                 if (isThisHovered) {
//                     currentStyle = {
//                         ...currentStyle,
//                         ...(isFemale ? cardHoverStyleFemale : cardHoverStyle)
//                     };
//                 }
//                 if (isSelected) {
//                     currentStyle = { ...currentStyle, ...chosenStyle };
//                 }
//                 // console.log('item.users_id = ', item.users_id);
//                 return (
//                     <div
//                         style={currentStyle}
//                         onMouseEnter={() => setHoveredId(item.id)}
//                         onMouseLeave={() => setHoveredId(null)}
//                         key={item.id}
//                         onClick={() => { onClickCat({ catId: item.id, userId: item.users_id }); setSelectedId(item.id) }}
//                     >
//                         <div style={idStyle}><small>Id: {item.id}</small></div>
//                         <img style={imgStyle} src={item.cat_ava_url} alt="cat" />
//                         <div style={first}>
//                             <div style={itemStyle}><b>{item.cat_name_cattery_prefix} {item.cat_name} {item.cat_name_suffix}</b></div>
//                             {/* <div style={itemStyle}><b>{item.cat_name}</b></div>
//                             <div style={itemStyle}><b>{item.cat_name_suffix}</b></div> */}
//                         </div>
//                         <div style={second}>
//                             <div style={itemStyle}><em>{item.cat_birthdate}</em></div>
//                             <div style={emsStyle}>{item.cat_color}</div>
//                             {item?.registered ? `Reg` : ''}
//                             <div>{(item?.is_my_cat & item?.users_id !== userId) ? 'Owned' : ''} </div>
//                             <div style={emsStyle}>{item.ems}</div>
//                         </div>
//                         {/* <div style={second}>
//                             <img style={imgStyle} src={item.cat_ava_url} alt="cat" />
//                             <div style={itemStyle}><em>{item.cat_birthdate}</em></div>
//                             <div style={emsStyle}>{item.cat_color}</div>
//                             {item?.registered ? `Reg` : ''}
//                             <div>{(item?.is_my_cat & item?.users_id !== userId) ? 'Owned' : ''} </div>
//                             <div style={emsStyle}>{item.ems}</div>
//                         </div> */}
//                         {/* <div style={firstItemStyle}><small>Id: {item.id}</small></div>
//                         <div>
//                             <div style={itemStyle}><b>{item.cat_name_cattery_prefix}</b></div>
//                             <div style={itemStyle}><b>{item.cat_name}</b></div>
//                             <div style={itemStyle}><b>{item.cat_name_suffix}</b></div>
//                         </div>
//                         <div style={itemStyle}><small>{item.cat_birthdate}</small></div>
//                         <div style={preLastItemStyle}>{item.cat_color}</div>
//                         <div style={lastItemStyle}>{item.ems}</div> */}
//                     </div>

//                 )
//             })}
//         </div>
//     );
// }

// export default CatMiniCard