// ----- Transform age into readable sentance. ----- //
// ----- Age calculated on backend (catContext) and it is send within data as an { age }. ----- //
export const ageSentence = (cat, translations) => {
// console.log('ageSentence | cat: ', cat)
// console.log('ageSentence | translations: ', translations)
    let birthdate;
    let catAge;

    // Extracting the cat's age components
    let { years = '', months = '', days = '' } = cat?.age || {};

    // years = cat?.age?.years ? cat?.age?.years : '';
    // months = cat?.age?.months ? cat?.age?.months : '';
    // days = cat?.age?.days ? cat?.age?.days : '';

    //  ------ if no age it comes as an empty string {age: ''} ------ //
    if (cat?.age) {
        const yearsTranslation = cat?.age?.years === 1
            ? translations['year']
            : cat?.age?.years > 4
                ? translations['let_years']
                : translations['years'];
        const monthsTranslation = cat?.age?.months === 1
            ? translations['month']
            : cat?.age?.months > 4
                ? translations['months5']
                : translations['months'];
        let daysTranslation = cat?.age?.days === 1
            ? translations['day']
            : (cat?.age?.days === 2 || cat?.age?.days === 3 || cat?.age?.days === 4)
                ? translations['2_3_4_dnya']
                : translations['days'];

        // Determining birthdate and age text
        birthdate = cat.cat_birthdate ? cat.cat_birthdate : '~';

        const yearsText = years ? `${years} ${yearsTranslation}` : '';
        const monthsText = months ? `${months} ${monthsTranslation}` : '';
        const daysText = (days > 0) ? `${days} ${daysTranslation}` : '';

        // Formatting the cat's age
        catAge = days ? `(${yearsText} ${monthsText} ${daysText})` : `(${yearsText} ${monthsText})`;
    }
    return { birthdate, catAge };
}

export default ageSentence;