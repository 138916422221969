import React, { useEffect, useState } from 'react'
import { Navigate, useLocation, useParams } from 'react-router-dom'
import { useAuth } from './authContext'
import { fetchUserIdByCatId } from '../services/api';
import ErrorPageNotAuth from '../pages/ErrorPageNotAuth/ErrorPageNotAuth';

export const RequireAuth = ({ children, roles }) => {
    // console.log('RequireAuth evoked')
    const location = useLocation();
    const auth = useAuth()
    const currentUserId = auth.currentUser?.id;
    // console.log('auth.currentUser.roleIds = ', auth.currentUser.roleIds)
    const par = useParams(); // cat ID from the URL
    const catId = par.id;
    const [userIdTryToAccess, setUserIdTryToAccess] = useState(null);


    useEffect(() => {
        // console.log('RequireAuth useEffect evoked')
        if (location.pathname.startsWith('/cats/')) {

            const fetchUserId = async () => {
                try {
                    const res = await fetchUserIdByCatId(catId); //we need to get user_id
                    // userIdTryToAccess = res.users_id;
                    setUserIdTryToAccess(res.users_id);
                    // console.log('userIdTryToAccess = ', userIdTryToAccess)
                    // return userIdTryToAccess;
                } catch (error) { console.log(error) }
            }
            fetchUserId();
        }

    }, [location.pathname, catId, currentUserId]);

    if (location.pathname.startsWith('/cats/')) {
        if (userIdTryToAccess && currentUserId !== userIdTryToAccess && !auth.currentUser.roleIds.includes(2)) {
            return <ErrorPageNotAuth />;
        }
    }
    // Call your API or perform the check to see if the user can access this cat
    // We need to get user_id asociated with catId from cat table 

    // Check user authorization after userIdTryToAccess is set

    //Checking if the user is not logged in
    if (!auth.currentUser) {
        return <Navigate to='/' state={{ path: location.pathname }} />
    }

    // Check if the current user's role is included in the roles permitted for this route
    // const hasRequiredRole = roles.some(role => auth.currentUser?.roleIds?.includes(role));
    const hasRequiredRole = auth.currentUser?.roleIds.find(role => roles?.includes(role))
    if (hasRequiredRole) {
        return children;
    }

}

// Need to add module<RequireAuth> into App.js         
// path: "/my",
// element: <RequireAuth><Profile /></RequireAuth>,