import { useEffect } from 'react'

const Metrika = () => {

  useEffect(() => {
    // console.log('Metrika evoked in useEffect!')
    // window.ym(95428596, 'hit', '/login')
    if (window.location.hostname !== 'localhost') {
      window.ym(95428596, 'hit', '/join',
        {
          params: {
            title: 'Join',
            referer: 'http://catclub.ru/join'
          }
        })
      window.ym(95428596, 'hit', '/my',
        {
          params: {
            title: 'My',
            referer: 'http://catclub.ru/my'
          }
        })
      window.ym(95428596, 'hit', '/login',
        {
          params: {
            title: 'Login',
            referer: 'http://catclub.ru/login'
          }
        }
      );
    }
  }, [])

  return null

}

export default Metrika

// ym(XXXXXX, 'hit', '#contacts', {params:{
//   title: 'Контактная информация',
//   referer: 'http://example.com/#main'
// }});