// OrderForm.js
import { LanguageContext } from "../../context/langContext";
import React, { useContext, useEffect, useState } from 'react';
import { faMarsAndVenus } from "@fortawesome/free-solid-svg-icons";


import './order.scss';
import CatCardSell from "../../components/CatCardSell/CatCardSell";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sendOrder } from "../../services/api";
import { useAuth } from "../../context/authContext";
import { SaleInformationOneCatContext, SaleInformationOneCatProvider } from "../../context/SaleInformationOneCatContext";
import { CatContext, CatContextProvider } from "../../context/CatContext";

//The data can be passedd to Order either using location.state or via catContext
// const cat = location.state?.saleCat; // Access the passed state from CarCardSell function
// console.log('Order | salecat = location.state?.saleCat = ', cat); //only cat information


const Order = () => {
    const urlParts = window.location.pathname.split('/');
    // console.log('urlParts = ', urlParts)
    const catId = urlParts[urlParts.length - 1];
    // console.log("Order | catId", catId)
    return (
        <CatContextProvider catId={catId}>
            <SaleInformationOneCatProvider catId={catId}>
                <OrderChild />
            </SaleInformationOneCatProvider>
        </CatContextProvider>
    );
}

//we send currentUserId (on backend we will fetch his email, name, phone number), info about cat, info about order
export const handleOrder = async (withBreeding, {
    currentUserBuyerId,
    currentUserBuyerEmail,
    currentUserBuyerFirstName,
    cat,
    saleInformation,
    comment,
    delivery,
    navigate
}) => {
    const orderDetails = {
        currentUserBuyerId,
        currentUserBuyerEmail,
        currentUserBuyerFirstName,
        catId: cat.id,
        breederId: cat?.breeder_id,
        comment,
        delivery,
        price: withBreeding ? saleInformation?.price_breeding : saleInformation?.price_pet,
        currencyId: cat?.currency_id,
        saleInformationId: saleInformation?.id,
        online_owner_id: cat?.online_owner_id
    };
    console.log('OrderChild | handleOrder | orderDetails', orderDetails)
    try {
        const response = await sendOrder(orderDetails);
        if (response) {
            navigate('/order/sent')
            console.log('response', response);
        } else {
            console.warn('OrderChild | handleOrder | No response from sendOrder');
        }
    } catch (error) {
        if (error.response) {
            // Server responded with a status other than 2xx
            console.error('OrderChild | handleOrder | Server Error:', error.response.data.message || error.message);
        } else if (error.request) {
            // Request was made but no response was received
            console.error('OrderChild | handleOrder | Network Error: No response received', error.message);
        } else {
            // Something else happened while setting up the request
            console.error('OrderChild | handleOrder | Error:', error.message);
        }
    }
};

const OrderChild = () => {
    const { translations } = useContext(LanguageContext)
    const { data: cat } = useContext(CatContext);
    const { data: saleData, loading: saleLoading, error: saleError } = useContext(SaleInformationOneCatContext);
    console.log('OrderChild | const { cat } = useContext(CatContext) | cat  = ', cat)
    // console.log("saleData", saleData)

    const auth = useAuth();
    const currentUserBuyerId = auth.currentUser.id;
    const navigate = useNavigate();

    const [comment, setComment] = useState('');
    const [delivery, setDelivery] = useState(false);
    const [currentUserBuyerEmail, setBuyerEmail] = useState('');
    const [currentUserBuyerFirstName, setCurrentUserBuyerFirstName] = useState('');

    const saleInformation = saleData?.data[0]

    //The data can be passedd to Order either using location.state or via catContext
    // const cat = location.state?.saleCat; // Access the passed state from CarCardSell function
    // console.log('Order | salecat = location.state?.saleCat = ', cat); //only cat information

    useEffect(() => {
        const storedUser = localStorage.getItem('userKey');
        if (storedUser) {
            const userObject = JSON.parse(storedUser);
            setBuyerEmail(userObject.email);
            setCurrentUserBuyerFirstName(userObject.first_name)
        } else {
            console.log('No user found in localStorage');
        }
    }, []);

    if (!saleData) { return <div>No data</div> }

    // Handle loading states
    if (saleLoading) return <div>Loading...</div>;

    // // Handle errors
    if (saleError) return <div>Error: {saleError?.message}</div>;


    //we send currentUserId (on backend we will fetch his email, name, phone number), info about cat, info about order
    const handleOrder = async (withBreeding) => {
        const orderDetails = {
            currentUserBuyerId,
            currentUserBuyerEmail,
            currentUserBuyerFirstName,
            catId: cat.id,
            breederId: cat?.breeder_id,
            comment,
            delivery,
            withBreeding,
            price: withBreeding ? saleInformation?.price_breeding : saleInformation?.price_pet,
            currencyId: cat?.currency_id,
            saleInformationId: saleInformation?.id,
            online_owner_id: cat?.online_owner_id
        };
        console.log('OrderChild | handleOrder | orderDetails', orderDetails)
        try {
            const response = await sendOrder(orderDetails);
            if (response) {
                navigate('/order/sent')
                console.log('response', response);
            } else {
                console.warn('OrderChild | handleOrder | No response from sendOrder');
            }
        } catch (error) {
            if (error.response) {
                // Server responded with a status other than 2xx
                console.error('OrderChild | handleOrder | Server Error:', error.response.data.message || error.message);
            } else if (error.request) {
                // Request was made but no response was received
                console.error('OrderChild | handleOrder | Network Error: No response received', error.message);
            } else {
                // Something else happened while setting up the request
                console.error('OrderChild | handleOrder | Error:', error.message);
            }
        }
    };

    const handleCancel = () => {
        if (window.history.length > 1) {
            navigate(-1); // Navigate back to the previous page
        } else {
            navigate('/defaultPage'); // Replace with your default page route
        }
    };

    return (
        <div className="order__container">
            <div className="form__container">
                <form className="order__form">
                    <div>
                        {translations.order.your_message}
                    </div>
                    <div>
                        <p className="order__p" htmlFor="name">{translations.order.message}</p>
                        <textarea
                            className="textarea"
                            id="comment"
                            name="comment"
                            rows="5"
                            cols="40"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)} />
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            id="delivery"
                            name="delivery"
                            checked={delivery}
                            onChange={(e) => setDelivery(e.target.checked)}
                        />
                        <label htmlFor="email">{translations.order.delivery}</label>
                    </div>
                </form>
                <button className='order__button' onClick={() => handleOrder(false)}>
                    <div className="order__button_text">{translations.order.submit_order}</div>
                    <div className="order__button_price">{saleInformation?.price_pet} {cat?.currency_abb}</div>
                </button>
                <button className='order__button' onClick={() => handleOrder(true)}>
                    <div className="order__button_text">{translations.order.submit_order_with_breeding}</div>
                    <div className="order__button_price">
                        <FontAwesomeIcon className="icon__mars-and-venus" icon={faMarsAndVenus} />
                        {saleInformation.price_breeding} {cat?.currency_abb}
                    </div>
                </button>
                <button className="button-light" onClick={handleCancel}>{translations.cancel}</button>
                {/* <button className="button-light" onClick={()=> {navigate('../market')}}>{translations.cancel}</button> */}
            </div>
            {cat && <CatCardSell catId={cat.id} />}
        </div>
    );
};

export default Order