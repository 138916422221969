import { useCallback, useEffect, useState } from 'react';
import { useAuth } from '../../context/authContext.js';

//My modules//
import "./mySell.scss";
import CatCard from "../CatCard/CatCard.jsx";
import Pagination from '../ui/pagination.jsx';
import { paginate } from '../../utils/paginate.js';
import ListGroup from '../ui/ListGroup.jsx'
import { getMyCats } from '../../services/api.js';

const MySell = () => {
    // const [notMyCats, setNotMyCats] = useState([]);
    const [allCats, setAllCats] = useState([]); //an array of my cats
    const [numberOfCats, setNumberOfCats] = useState(0)
    // const [numberOfNotMyCats, setNumberOfNotMyCats] = useState(0)
    const [selectedSexFilter, setSelectedSexFilter] = useState('all')
    // const [selectedFilterMy, setSelectedFilterMy] = useState('All')
    const [currentPage, setCurrentPage] = useState(1);
    // const [currentArrayOfCats, setCurrentArrayOfCats] = useState([]);
    // const [pageSize, setPageSize] = useState(5);
    const pageSize = 5;
    // const [filtered, setFiltered] = useState([]);
    const auth = useAuth();
    const currentUserId = auth.currentUser.id;

    const sexFilterNames = ['all', 'female', 'male'];

    useEffect(() => {
        const fetchDataSellCats = async () => {
            try {
                const resCatsForSale = await getMyCats(true); //{isSell: true}
                // console.log('Cats for sale:', resCatsForSale);
                setAllCats(resCatsForSale);
                setNumberOfCats(resCatsForSale.length)

            } catch (error) {
                console.log(error);
            }
        }
        fetchDataSellCats()
    }, [currentUserId])

    const handleSexFilterSelect = useCallback((filter) => {
        setSelectedSexFilter(filter);
        setCurrentPage(1);
    }, []);

    const handlePageChange = useCallback((page) => {
        setCurrentPage(page);
    }, []);

    const filteredCats = allCats.filter(allCats => {
        if (selectedSexFilter !== 'all' && allCats.sex !== selectedSexFilter) {
            return false;
        }
        return true
    })

    const paginatedCats = paginate(filteredCats, currentPage, pageSize); //paginate function returns an array of cats

    return (
        <div className="cats-container">
            <h1>Sell</h1>
            <div className="cats__upperBlock">
                {/* <ListGroup numberOfCats={numberOfCats} selectedFilter={selectedFilterMy} onFilterSelect={handleFilterSelectMy} items={catOwnershipFilterNames} /> */}
                <ListGroup numberOfCats={numberOfCats} selectedFilter={selectedSexFilter} onFilterSelect={handleSexFilterSelect} items={sexFilterNames} />
            </div>
            <div className="catblock__row">
                {paginatedCats?.map((catt) => (
                    <CatCard
                        key={catt.cat_id}
                        cat_name={catt.cat_name}
                        registered={catt.registered}
                        cat_name_cattery_prefix={catt.cat_name_cattery_prefix}
                        cat_name_suffix={catt.cat_name_suffix}
                        sex={catt.sex}
                        cat_birthdate={catt.cat_birthdate}
                        id={catt.cat_id}
                        users_id={catt.users_id} cover={catt.cat_ava_url}
                        is_my_cat={catt.is_my_cat} />
                ))}
            </div>
            <Pagination
                itemsCount={filteredCats.length}
                numberOfCats={numberOfCats}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={handlePageChange} />
        </div>
    )
}

export default MySell
