import React from 'react';
import './privacy.scss';

const PrivacyPolicy = () => {
    return (
        <div className='privacy-policy'>

            <em>
                Our Privacy Policy explains what personal information we collect, how we use personal information, how personal information is shared, and privacy rights.
            </em>

            <h1>Privacy Policy for CatClub</h1>

            <p>Last Updated: 8th of January 2024</p>

            <h2>1. Introduction</h2>

            <p>
                Welcome to CatClub! We are committed to protecting the privacy and security of our members' information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website [insert website address], participate in our forum, or use our services.
            </p>

            <h2>2. Information We Collect</h2>

            <p>
                We may collect information about you in a variety of ways including:
            </p>

            <ul>
                <li><strong>Personal Data:</strong> Personally identifiable information, such as your name, email address, and telephone number, that you voluntarily give to us when you register with the CatClub or when you choose to participate in various activities related to the CatClub, such as online chat and message boards.</li>
                <li><strong>Derivative Data:</strong> Information our servers automatically collect when you access the CatClub, such as your IP address, browser type, operating system, access times, and the pages you have viewed directly before and after accessing the CatClub.</li>
                <li><strong>Financial Data:</strong> Financial information, such as data related to your payment method (e.g., valid credit card number, card brand, expiration date) that we may collect when you purchase, order, return, exchange, or request information about our services from the CatClub.</li>
                <li><strong>Data from Contests, Giveaways, and Surveys:</strong> Personal and other information you may provide when entering contests or giveaways and/or responding to surveys.</li>
            </ul>

            <h2>3. Use of Your Information</h2>

            <p>
                Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the CatClub to:
            </p>

            <ul>
                <li>Create and manage your account.</li>
                <li>Email you regarding your account or orders.</li>
                <li>Fulfill and manage purchases, orders, payments, and other transactions related to the CatClub.</li>
                <li>Offer new products, services, and/or recommendations to you.</li>
                <li>Perform other business activities as needed.</li>
            </ul>

            <h2>4. Disclosure of Your Information</h2>

            <p>
                We may share information we have collected about you in certain situations. Your information may be disclosed as follows:
            </p>

            <ul>
                <li><strong>By Law or to Protect Rights:</strong> If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.</li>
                <li><strong>Third-Party Service Providers:</strong> We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.</li>
                <li><strong>Marketing Communications:</strong> With your consent, or with an opportunity for you to withdraw consent, we may share your information with third parties for marketing purposes, as permitted by law.</li>
            </ul>

            <h2>5. Security of Your Information</h2>

            <p>
                We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.
            </p>

            {/* <h2>6. Contact Us</h2>

            <p>
                If you have questions or comments about this Privacy Policy, please contact us at: [insert contact information].
            </p> */}

        </div>
    )
}

export default PrivacyPolicy
