import { Link } from "react-router-dom";
import './catcard.scss';
// import catAva from "../../img/catAva.jpg";
import formatDateFromUTC from '../../utils/formatDateFromUTC';
import { useContext } from "react";
import { CatContext, CatContextProvider } from "../../context/CatContext";
// import { LanguageContext } from "../../context/langContext";
import formatDateFromDB from '../../utils/formatDateFromDB';

const CatCard = (props) => {
    // const urlParts = window.location.pathname.split('/');
    // // console.log('urlParts = ', urlParts)
    // const catId = urlParts[urlParts.length - 1];
    // console.log('CatProfile | catId', catId)

    // props - parent's data from ParentsTab
    return (
        <CatContextProvider catId={props.id} key={props.id}>
            <CatCardChild />
        </CatContextProvider>
    );
}

const CatCardChild = () => {
    const { data } = useContext(CatContext);
    // console.log('CatCardChild | data', data);
    // const cat = data;
    // const { translations, language } = useContext(LanguageContext)

    const catAva = '/images/catAva.jpg'
    const sex = data?.sex;
    const cat_birthdate = data?.cat_birthdate;
    const id = data?.id;
    const cover = data?.cat_ava_url;
    const cat_name = data?.cat_name;
    const cat_name_cattery_prefix = data?.cat_name_cattery_prefix;
    const cat_name_suffix = data?.cat_name_suffix;
    const registered = data?.registered
    const is_my_cat = data?.is_my_cat
    // const { sex, cat_birthdate, id, cover, cat_name, cat_name_cattery_prefix, cat_name_suffix, registered, is_my_cat } = data

    let textColorClass = 'catblock__item';
    let textColorCalssMale = 'male'
    let textColorCalssFemale = 'female'

    if (sex === 'male') {
        textColorClass += ' ' + textColorCalssMale;
    } else if (sex === 'female') {
        textColorClass += ' ' + textColorCalssFemale;
    }

    //const baseClass = 'catblock__item';
    // const textColorClass = `${baseClass} ${sex === 'male' ? 'male' : (sex === 'female' ? 'female' : '')}`; - more optimized version
    const newBirthDate = formatDateFromUTC(cat_birthdate)

    return (

        <Link className={textColorClass} to={`/cats/${id}`}>
            <div className="cat-card-flex">
                <small>{sex}</small>
                <small>ID: {id}</small>
                {registered !== 0 && <div className='cat-card__registered'>Reg</div>}
                {is_my_cat === 1 && <div className='cat-card__is-my-cat'>My</div>}
            </div>
            <img className='catimg' src={cover ? cover : catAva} alt={cover ? ('Photo of ' + cat_name) : ('catAva')} />
            <h3>{cat_name_cattery_prefix} {cat_name} {cat_name_suffix && cat_name_suffix} </h3>
            <span>🎂: {cat_birthdate ? newBirthDate : '~'}</span>
        </Link>
    )
}

export default CatCard;