import React, { createContext, useMemo, useState } from 'react'
import EnterEmail from './Restore/EnterEmail';
import OTPInput from './Restore/OTPInput';
import Reset from './Restore/Reset';
import Recovered from './Restore/Recovered';

export const RecoveryContext = createContext();

function IndexRestore() {
    const [page, setPage] = useState('enterEmail');
    const [email, setEmail] = useState('');
    const [otp, setOTP] = useState();

    // useMemo will return a memoized version of the context value
    // which only changes if one of the dependencies change
    const contextValue = useMemo(() => ({ page, setPage, otp, setOTP, email, setEmail }), [page, otp, email]);

    function NavigateComponents() {
        if (page === "enterEmail") return <EnterEmail />;
        if (page === "otp") return <OTPInput />;
        if (page === 'reset') return <Reset />;
        return <Recovered />
    }
    return (
        <RecoveryContext.Provider
            value={contextValue}>
            <div className="">
            {/* {console.log("IndexRestore render")} */}
                <NavigateComponents />
            </div>
        </RecoveryContext.Provider>
    )
}

export default IndexRestore
