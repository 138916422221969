// import axios from 'axios';
import React, { useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import FormInput from '../../components/FormInput/FormInput';
// import "./join.scss"
import "./login.scss";
// import { AuthContextProvider } from '../context/authContext.js';
import { useAuth } from '../../context/authContext.js';
// import ym from 'react-yandex-metrika';
import { useContext } from "react";
import { LanguageContext } from "../../context/langContext";

const Login = () => {

  // const [err, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const { translations } = useContext(LanguageContext)
  const [errorMessage, setErrorMessage] = useState('');
  // const userRef = useRef();
  // const from = location.state?.from?.pathname || '/';

  //   ym(95428596, 'hit', '#contacts', {params:{
  //     title: 'Login',
  //     referer: 'http://catclub.ru/login'
  // }});

  // console.log('location.state.from.pathname = ', location.state.from.pathname)
  const redirectPath = location.state?.path || '/';

  const handleChange = e => {
    // console.log('inputs e.target = ', e.target)
    const { name, value } = e.target;
    setInputs((prev) => ({ ...prev, [name]: value }));
    // setInputs(prev => ({ ...prev, [e.target.name]: e.target.value }))
  };

  const [inputs, setInputs] = useState({ email: "", password: "" });
  // our object in useState - used in the first time rendering
  const logintInputs = [
    {
      id: "email",
      name: "email",
      type: "email",
      autoComplete: "username",
      placeholder: translations.join?.email_placeholder,
      errorMessage: translations.login?.password_error,
      label: translations.join?.email_label,
      required: true,
      value: inputs.email,
      onChange: handleChange
    },
    {
      id: "password",
      name: "password",
      type: "password",
      autoComplete: "current-password",
      placeholder: translations.join?.password_placeholder,
      errorMessage: translations.join?.password_error,
      label: translations.join?.password_label,
      pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^*]{8,20}$`, //`^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^＆*]{8,20}$`,
      required: true,
      onChange: handleChange,
      value: inputs.password,
    },
  ]


  const handleLogin = async (e) => {
    e.preventDefault()
    // ym('hit', '/login');
    try {
      // auth.login(inputs);
      const response = await auth.login(inputs); //login function from authContext
      // console.log('Login.jsx | handleLogin | response: ', response)
      if (response && response.success) {
        navigate(redirectPath, { replace: true });
        // Handle successful login, e.g., redirect to the dashboard
      } else if (response && !response.success) {
        // return { success: false, message: 'Wrong username or password. Please try again.' };
        console.log(response.message);
        setErrorMessage(response.message) // response.message from authContext: "Wrong username or password. Please try aagain."
      }
      //window.location = '/' 
      //This code snippet directly modifies the window.location object to change the current URL of the page. 
      //It triggers an immediate redirect to the specified URL. 
      //This method is not tied to any specific routing library and can be used in plain JavaScript for simple page redirections. 
      //It does not provide options for controlling the history stack or other navigation-related features.
      //In summary, if you are using a routing library like react-router-dom, 
      //   it is recommended to use the navigate function for navigation within your application, as it provides better control over the navigation process and integrates well with the routing system. On the other hand, if you just need a simple redirect to a different URL, you can use window.location directly.
    }
    catch (err) {
      console.log('err = ', err)
      setErrorMessage('An unexpected error occurred. Please try again later.');
    }
  }


  return (
    <div className="login">
      <form className="joinform" onSubmit={handleLogin}>
        <h1>{translations.auth.login}</h1>
        {logintInputs.map((input) => (
          <FormInput
            key={input.id}
            {...input}
          // id={input.id}
          // value={inputs[logintInputs.name] || ""} 
          // onChange={input.onChange} 
          />
        ))
        }
        {errorMessage && <div className='errorMessage'>{errorMessage}</div>}
        <button className='joinButton'>{translations.send}</button>
        {/* {err && <p>{err}</p>} */}
        <span>{translations.login.dont_you_have_an_account} <Link to="/join">{translations.auth.join}</Link></span>
        <span><Link to="/restore">{translations.login.restore}</Link> </span>
        {errorMessage && (
          <div style={{ color: 'red', marginTop: '10px' }}>
            {errorMessage}
          </div>
        )}
      </form>
    </div>
  )

}

export default Login
