
//  ----- convert from UTC 2023-02-05T22:00:00.000Z into 31 Dec 2018 ----- //

function formatDateFromUTC(dateString) {
    // console.log('formatDate front', dateString) //2023-02-05T22:00:00.000Z
    // Parse the input UTC date string into a Date object
    const utcDate = new Date(dateString);

    // Convert UTC date to local time
    const localDate = new Date(utcDate.getTime() + (utcDate.getTimezoneOffset() * 60000));

    // Array of month names
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const day = localDate.getDate();
    const monthName = months[localDate.getMonth()];
    const year = localDate.getFullYear();

     // Format the date as "31 Dec 2018"
    const formattedDate = `${day} ${monthName} ${year}`;
    // console.log(formattedDate); // Output: "31 Dec 2018"
    return formattedDate;
    // return `${day} ${monthName} ${year}`;
}


export default formatDateFromUTC