import React, { useState, useEffect, useContext } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';

import './addParentSearch.scss'
import { useAuth } from '../../context/authContext';
import { fetchCatsWithColor10ByGender, postFather, postMother } from '../../services/api';
import CatMiniCard from '../CatMiniCard';
import { LanguageContext } from '../../context/langContext';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const AddParentSearch = () => {
    const { translations, language } = useContext(LanguageContext)

    // const [catsSelect, setCatsSelect] = useState({
    //     id: "",
    //     cat_name: "",
    //     sex: "male",
    //     cat_birthdate: "",
    //     breed_id: "",
    //     users_id: ""
    // });
    const auth = useAuth();
    const location = useLocation()
    let queryParentFromURL = useQuery();

    const parentGender = queryParentFromURL.get("p");  // This will get the value of the q parameter, so "father" in your example

    let catId = location.pathname.split("/")[2] //http://localhost:3001/post/1 - we take post number from URL address in browser
    catId = parseInt(catId, 10)

    const [allCats, setAllCats] = useState([])
    const [selectedOption, setSelectedOption] = useState(null);
    const [query, setQuery] = useState("");

    useEffect(() => {
        const fetchAllCats = async () => {
            try {
                const resData = await fetchCatsWithColor10ByGender(query, parentGender)
                // setCatsSelect('resData', resData);
                // const onlyMyCats = res.data.filter((cat) => { return cat.users_id !== auth.currentUser.id })
                // const allCats = res.data;
                console.log(' AddParentSearch | resData = ', resData)
                setAllCats(resData);
            } catch (error) { console.log(error) }
        }
        if (query.length === 0 || query.length > 2) fetchAllCats();
    }, [auth.currentUser, query, parentGender])

    const navigate = useNavigate()

    const handleAddMother = async e => {
        e.preventDefault();
        const motherId = selectedOption.catId;
        // console.log("motherId", motherId);
        try {
            const response = await postMother(motherId, catId)
            console.log('response.data', response.data);
            navigate(`/cats/${catId}`)
        } catch (error) {
            console.log(error)
        }
    }

    const handleAddFather = async e => {
        e.preventDefault();
        const fatherId = selectedOption.catId;
        console.log('catId', catId)
        console.log("fatherId", fatherId);
        try {
            const response = await postFather(fatherId, catId)
            console.log(response.data);
            navigate(`/cats/${catId}`)
        } catch (error) {
            console.log(error)
        }
    }

    // const handleFilterSelect = (filter) => {
    //     setSelectedFilter(filter);
    // }

    // const keys = ['cat_name', 'cat_name_cattery_prefix', 'cat_name_suffix']
    // const search = (data) => {
    //     return data.filter((item) =>
    //         keys.some((key) => item[key]?.toLowerCase().includes(query))
    //     );
    // }


    const onClickCat = (e) => {
        setSelectedOption(e)
    }

    return (
        // <div className="add-parent-searcht__container">
            <div className="add-parent-search" >
                <form>
                    <h1>{translations.search_parent.header} {catId}</h1>
                    <p className='searchCat__p'>{translations.search_parent.a}</p>
                    <p>{translations.search_parent.b}</p>

                    {/* <div className="cats__upperBlock">
                    <ListGroup selectedFilter={selectedFilter} onFilterSelect={handleFilterSelect} items={items} />
                </div> */}

                    <div className="search">
                        <input type="text" placeholder='Search...' className="search__input" onChange={(e) => setQuery(e.target.value.toLowerCase())} />
                    </div>
                    <p className='searchCat__p'>You see here only a small part of records. Start typing to see more results</p>
                    <p className='searchCat__p'>If you see 2 similar cats and one is registered - choose registered cat.</p>
                    <CatMiniCard data={allCats} onClickCat={onClickCat} />
                    {/* <CatMiniCard data={search(allCats)} onClickCat={onClickCat} /> */}
                    {parentGender === 'male' ? (
                        <button className="joinButton" onClick={handleAddFather}>{translations.search_parent.add_father} </button>
                    ) : (
                        <button className="joinButton" onClick={handleAddMother}>{translations.search_parent.add_mother} </button>
                    )}

                    <div className="searchCat__button">
                        <Link to={`/cats/${catId}`}><button className="button-light">{translations.search_parent.back_to_cat_page}</button></Link>
                        <Link to={`/addwithcolor`}><button className="button-light-main">{translations.search_parent.add_a_cat_with_color}</button></Link>
                    </div>
                </form>
            </div >
        // </div>
    )
}

export default AddParentSearch
