import React, { createContext, useEffect, useState } from 'react';
import { fetchSellingInformationById } from '../services/api';
import formatDate from '../utils/formatDateFromUTC';
import formatDateTime from '../utils/formatDateTime';
import formatDateFromDB from '../utils/formatDateFromDB';

const SaleInformationOneCatContext = createContext();

function SaleInformationOneCatProvider({ children, catId }) {
  // console.log("SaleInformationOneCatProvider | catId", catId)
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // const getCatIdFromCurrentUrl = () => {
  //   const urlParts = window.location.pathname.split('/');
  //   console.log('urlParts = ', urlParts)
  //   const id = urlParts[urlParts.length - 1];
  //   // Check if id is a valid number, if not return null
  //   return isNaN(id) ? null : id;
  // };

  // const catId = useMemo(() => getCatIdFromCurrentUrl(), []);
  // console.log('SaleInformationOneCatProvider | catId', catId)
  useEffect(() => {
    const fetchSaleData = async () => {
      setLoading(true);
      try {
        const saleData = await fetchSellingInformationById(catId);
        if (saleData.status === 'success' && saleData.data.length > 0) {
          const newReadyDate = formatDate(saleData.data[0].ready_to_move_at)
          saleData.data[0].ready_to_move_at = newReadyDate;
          const updatedNewDate = formatDateTime(saleData.data[0].updated_at);
          saleData.data[0].updated_at = updatedNewDate;
          // console.log('SaleInformationOneCatContext | saleData', saleData);
          const formattedMoveDateInput = formatDateFromDB(saleData.data[0].ready_to_move_at);
          saleData.data[0].ready_to_move_at_input = formattedMoveDateInput;

          setData(saleData);

          // setActive(sellingData.is_active === 1);
        } else if (saleData.status === 'no_data') {
          console.log('No data:', saleData.message);
          // Handle no data found case
          // setSellData('no data')
        } else {
          console.log('Error:', saleData.message);
        }
      } catch (err) {
        console.error('Error fetching sale data:', err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    if (catId) {
      fetchSaleData();
    }
  }, [catId]);

  const contextValue = { data, setData, loading, error }


  return (
    <SaleInformationOneCatContext.Provider value={contextValue}>
      {children}
    </SaleInformationOneCatContext.Provider>
  );
}

export { SaleInformationOneCatContext, SaleInformationOneCatProvider };

// Improvements Explained:
// Error Handling:
// Added error state to capture and provide feedback in case the data fetching fails.
// The try-catch block within the fetchSaleData function allows the component to gracefully handle errors.
// Loading State:
// Added a loading state to indicate when the data is being fetched. This is useful for showing loading indicators in the UI.
// Memoization:
// Wrapped the getCatIdFromCurrentUrl call in useMemo to avoid recalculating the cat ID unnecessarily. This helps with performance, especially if the URL path changes frequently or the component re-renders often.
// Similarly, useMemo is used to memoize the context value, ensuring that the context provider only updates its value when the relevant state variables (data, loading, error) change.
// Conditionally Fetching Data:
// The if (catId) check ensures that the fetch operation only occurs when a valid cat ID is present, preventing unnecessary API calls.
// Cleaner Code Organization:
// Simplified the getCatIdFromCurrentUrl function by directly returning the result, making it more readable.
// Consolidated the context value into a single contextValue object, which is cleaner and easier to manage.