import { Link } from "react-router-dom"
import catteryAva from "../../../img/pink-diploma.jpg";
import './CatteryTab.scss'



export const CatteryTab = ({ cattery }) => {

  return (
    <div className="profile-cattery">
      <div className="img-container">
        {cattery.image_url ? (<img src={cattery.image_url} alt="CatteryAva"></img>) : (<img src={catteryAva} alt="CatteryAva"></img>)}
        <div className="text-overlay">
          {cattery.length > 0 ? (
            <div className="cattery-with-breeder">
              <div className="cattery-with-breeder__names">
                <h2>My cattery</h2>
                <Link className='link strong' to={`/cattery/?id=${cattery[0].id}`}>{cattery[0].cattery_name}</Link><p><small>{cattery[0].cattery_name_full} | {cattery[0].abb} | issued: {cattery[0].certificate_issued_at}</small></p>
                {cattery.length > 1 &&
                  (<div className="cattery-with-breeder__text">
                    <Link className='link strong' to={`/cattery/?id=${cattery[1].id}`}>{cattery[1].cattery_name}</Link>
                    <p><small>
                      {cattery[1].cattery_name_full && (
                      <span>{cattery[1].cattery_name_full} | </span>)} {cattery[1].abb} | issued: {cattery[1].certificate_issued_at}</small></p>
                  </div>)
                }
              </div>
              {cattery.length < 4 &&
                (<div className="cattery-with-breeder__small">
                  <p>Do you have another cattery?</p>
                  <Link to={`/cattery`}><button className="profile-button">Add a cattery</button></Link>
                </div>)
              }
            </div>
          ) : (
            <div className="cattery-no-breeder">
              <h2>Are you a breeder?</h2>
              <Link to={`/cattery`}><button className="profile-button">Add a cattery</button></Link>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CatteryTab