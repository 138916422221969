
//Function format date from 2023-03-28T21:00:00.000Z to yyyy-MM-dd

function formatDateFromDB(dateString) {
  // console.log('dateString', dateString) // 2023-03-28T21:00:00.000Z
  // console.log("formatDateFromDB | dateString received:", dateString); // Add this line
  // Check if dateString is provided and is a valid date
  if (!dateString || isNaN(new Date(dateString).getTime())) {
    console.error("formatDateFromDB | Invalid date:", dateString);
    return ''; // or handle the error as needed
  }


  const date = new Date(dateString);
  // const isoDate = date.toISOString();
  // const year = isoDate.substring(0, 4);
  // const month = isoDate.substring(5, 7);
  // const day = isoDate.substring(8, 10);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth is 0-indexed
  const day = String(date.getUTCDate()).padStart(2, '0');


  return `${year}-${month}-${day}`; //yyyy-MM-dd - required format for input type='date'
}

export default formatDateFromDB