import React, { useContext, useEffect, useRef } from 'react'
// import FormInput from '../../components/FormInput/FormInput';
import { SendRecoveryEmail, checkEmailExists } from '../../services/api';
import { RecoveryContext } from '../IndexRestore';
import { useState } from 'react';
import { LanguageContext } from "../../context/langContext";

const EnterEmail = () => {
  const { setEmail, setPage, email, setOTP } = useContext(RecoveryContext);
  const [errorNoEmail, setErrorNoEmail] = useState('');
  const { translations } = useContext(LanguageContext)

  // const formValues =
  // {
  //   id: 1,
  //   name: "email",
  //   type: "email",
  //   autoComplete: "username",
  //   placeholder: "Enter your email address",
  //   errorMessage: "It should be a valid email address!",
  //   label: "Email",
  //   required: true,
  //   inputId: 'email'
  // }
  const inputRef = useRef() // Create a ref to the input field

  useEffect(() => {
    inputRef.current.focus(); //Focus the input field
  })

  const onChange = e => {
    e.preventDefault();
    const newEmail = e.target.value
    setEmail(newEmail)
  }

  async function navigateToOtp() {
    if (email) {
      const OTP = Math.floor(Math.random() * 9000 + 1000);
      console.log(OTP);
      setOTP(OTP);

      // setPage('otp');
      SendRecoveryEmail(email, OTP)
        .then(() => setPage('otp'))
        .catch(console.log('Catch'))
    }
  }

  // useEffect(() => {
  //   if(email) {
  //     console.log('email', email)
  //     navigateToOtp()
  //   }
  // }, [email])

  const handleReset = async (e) => {
    e.preventDefault();
    if (email) {
      console.log('email from EnterEmail', email)
      const resEmailExists = await checkEmailExists(email);
      console.log('resEmailExists', resEmailExists.data);
      if (resEmailExists.data.length === 0) {
        console.log('res null')
        setErrorNoEmail('No such user/email!')
      } else {
        navigateToOtp()
      }
    }
    // navigateToOtp()
    // setEmail(email)
    // const r = await setEmail(emailF)
    // if(r) navigateToOtp()
  }

  return (
    <div className="login">
      {/* {console.log('Rerender')} */}
      <form className="joinform" onSubmit={handleReset}>
        <h1>{translations.restore?.reset_password}</h1>
        <p>{translations.restore?.t1}</p>
        <p>{translations.restore?.t2}</p>
        <div className="form-input">
          <label className='form-input__label' htmlFor='email'>{translations.join?.email_label}</label>
          <input
            name='email'
            className="form-input__input"
            value={email}
            onChange={onChange}
            ref={inputRef}
            type="email"
            placeholder={translations.join.email_placeholder}
            autoComplete='username'
            maxLength={100}
            id='email' />
        </div>

        {/* <FormInput  {...formValues} value={email} onChange={onChange}  /> */}
        {errorNoEmail && <div className='errorMessage'>{errorNoEmail}</div>}
        <button type='submit' className='joinButton'>{translations.send}</button>
      </form>
    </div>
  )

}

export default EnterEmail
