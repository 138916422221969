import React from 'react'
import './terms.scss';

const Terms = () => {
    return (
        <div className='terms'>
            <h1>Terms and Conditions for CatClub</h1>
            <p>Last Updated: 8th of January 2023</p>

            <h2>1. Introduction</h2>
            <p>Welcome to CatClub! We are a dedicated online community for cat breeders to share, learn, and collaborate. By accessing our website and using our services, you agree to these Terms and Conditions.</p>

            <h2>2. Membership</h2>
            <h3>2.1. Eligibility</h3>
            <p>Membership is open to individual cat breeders and cattery owners. You must be at least 18 years old to join.</p>
            <h3>2.2. Account Creation</h3>
            <p>Members must provide accurate and up-to-date information during registration.</p>
            <h3>2.3. Membership Fees</h3>
            <p>If applicable, membership fees are due annually and are non-refundable.</p>

            <h2>3. Code of Conduct</h2>
            <h3>3.1. Ethical Breeding</h3>
            <p>Members must adhere to ethical breeding practices.</p>
            <h3>3.2. Respectful Communication</h3>
            <p>Members should interact respectfully with one another.</p>
            <h3>3.3. No Illegal Activities</h3>
            <p>Any illegal activities or animal abuse will result in immediate termination of membership.</p>

            <h2>4. Content and Intellectual Property</h2>
            <h3>4.1. User-Generated Content</h3>
            <p>Members are responsible for the content they post. This includes photos, forum posts, and comments.</p>
            <h3>4.2. Intellectual Property Rights</h3>
            <p>Members retain rights to their content but grant CatClub a license to use it for promotional or operational purposes.</p>

            <h2>5. Privacy and Data Protection</h2>
            <h3>5.1. Data Collection</h3>
            <p>We collect personal data necessary for membership management and service improvement.</p>
            <h3>5.2. Data Usage</h3>
            <p>Personal data will not be shared with third parties without consent, except as required by law.</p>

            <h2>6. Disclaimer of Warranties</h2>
            <p>CatClub provides services on an 'as is' basis and does not make any specific promises or warranties regarding the service, including its reliability, availability, or ability to meet your needs.</p>

            <h2>7. Limitation of Liability</h2>
            <p>To the extent permitted by law, CatClub shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues.</p>

            <h2>8. Amendments</h2>
            <p>We may update these terms from time to time. Continued use of the service after changes constitutes acceptance of the new terms.</p>
{/* 
            <h2>9. Governing Law and Jurisdiction</h2>
            <p>These terms are governed by [Applicable Law] and any disputes will be subject to the exclusive jurisdiction of the courts of [Jurisdiction].</p>

            <h2>10. Contact Information</h2>
            <p>For any questions or concerns regarding these terms, please contact us at [Contact Information].</p> */}
        </div>
    )
}

export default Terms
