import React from "react";
import { useState } from "react"
import './uploadFile.scss';
import { faCamera } from "@fortawesome/free-solid-svg-icons";

export const UploadFile = ({ onChangeFile }) => {
    const [selectedFile, setSelectedFile] = useState(null);

    // Save file as a state.
    const addImg = (e) => {
        let file = e.target.files[0]
        // console.log(e.target.files[0])
        if (file !== undefined) {
            setSelectedFile(file);
            onChangeFile(file);
        }
        else {
            console.log('No file')
            setSelectedFile()
        }
    }

    return (
        <div>
            <div className="upload-photo">
                <small>Accept only .jpeg, .jpg, .png, .gif</small>
                <label htmlFor="InputFile" className="upload-label">
                    <i className={faCamera}></i> Upload a photo
                </label>
                <input type="file" name="cat_photo" accept="image/png, image/gif, image/jpeg, image/jpg, .jpg, .jpeg, .png, .gif" id="InputFile" className="photo-input"
                    onChange={addImg} />
                {Boolean(selectedFile) && (
                    <div className="preview-container">
                        <img src={URL.createObjectURL(selectedFile)} alt="imgpreview" />
                    </div>)}
            </div>
        </div>
    )
}
