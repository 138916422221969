import React, { useContext } from 'react'
import styled from 'styled-components';
import { LanguageContext } from "../../context/langContext";

// Styled component for a centered container
const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
`;

const CenteredText = styled.div`
  font-size: 24px;
  color: #333;
`;

const Recovered = () => {
  const { translations } = useContext(LanguageContext)
  return (
    <CenteredContainer>
      <CenteredText>
        <h1>{translations.recovered.h1} {" "}</h1>
        <h3>{translations.recovered.h3} {" "} </h3>
      </CenteredText>
    </CenteredContainer>
  )
}

export default Recovered
