import React from 'react';
import './clue.scss';

export const Clue = (props) => {

    return (
            <p className='clue' data-clue={props.text}>?</p>
    )

}

// export default Clue;