// @flow
import { useContext } from 'react';
import { LanguageContext } from '../context/langContext';

// type Translations = {
//     hello: string,
//     // Add more translation keys and their types here
// };

export const useTranslations = () => {
    const context = useContext(LanguageContext);
    if (!context) {
        throw new Error('useTranslations must be used within a LanguageProvider');
    }
    const { translations, language } = context;
    return { translations, language };
};

// export default useTranslations;
