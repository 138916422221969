import React from 'react'
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from 'react';
import { useAuth } from '../context/authContext.js';
import FormInput from '../components/FormInput/FormInput.jsx';
import { changeEmail, fetchUserSettings } from '../services/api.js';

import './settings.scss'

const Settings = () => {

    const auth = useAuth();
    const currentUserId = auth.currentUser.id;
    const navigate = useNavigate();
    // console.log('auth =', auth)
    const [user, setUser] = useState({
        email: "",
    });

    const [writtenEmail, setWrittenEmail] = useState({
        email: "",
        id: auth.currentUser.id
    });

    const inputs = [
        {
            id: 1,
            name: "email",
            type: "email",
            placeholder: "Email",
            // errorMessage: "It should be a valid email address!",
            // label: "Email",
            // required: true,
            // className:"joinInput"
        },
    ]

    useEffect(() => {
        const fetchData = async () => {
            try {
                const resUser = await fetchUserSettings() //return res.data already
                // console.log('resUser', resUser)
                if (resUser) setUser(resUser);
            } catch (error) {
                console.log(error);
            }
        }
        fetchData(currentUserId)
    }, [currentUserId])

    const onChangeEmail = (e) => {
        setWrittenEmail((prev) => ({ ...prev, [e.target.name]: e.target.value }))
    };

    const onSubmitEmail = async (e) => {
        e.preventDefault();
        try {
            await changeEmail(writtenEmail)
        } catch (err) {
            console.log(err)
        }
        navigate('/my')
    }

    return (
        <div className='settings-container'>
            <p>Email: <strong>{user.email}</strong> </p>
            <form onSubmit={onSubmitEmail}>
                <FormInput
                    key={inputs[0].id}
                    name={inputs[0].name}
                    type={inputs[0].type}
                    placeholder={inputs[0].placeholder}
                    value={writtenEmail.email}
                    onChange={onChangeEmail}
                />
                <button type='submit'>Change email</button>
            </form>
        </div>
    )
}

export default Settings
