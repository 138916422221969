//Example of data expected: 
//const hostURL = 'http://localhost:8800/api/upload';
//Here data come from Add.jsx component


// const hostUrl = '/upload';
// const fileP = await HandleUpload(state.selectedFile, `${BASE_URL}/upload`, currentUserId, entityType, +state.values.catId);

export const HandleUpload = async (selectedFile, hostUrl, userId, prefix, catId) => {
    console.log('HandleUpload | selectedFile:', selectedFile);
    console.log('HandleUpload | catId:', catId);
    console.log(`
        HandleUpload | selectedFile: ${selectedFile}, hostUrl: ${hostUrl}, userId: ${userId}, prefix: ${prefix}, catId: ${catId}`)
    if (!selectedFile) {
        alert("Please select a file");
        return;
    };
    // console.log('userId from HandleUpload.js', userId);

    let formData = new FormData()
    formData.append('file', selectedFile, selectedFile.name);
    // const newUserId = userId.toString()
    formData.append('userId', userId.toString());
    formData.append('catId', catId.toString());
    formData.append('prefix', prefix.toString());
    // console.log('other from HandleUpload.js', other);
    // console.log('formData.get(userId) from HandleUpload', parseInt(formData.get('userId')))
    //The formData.get() method returns a string value, even if the original value passed to it was a number. 
    // console.log('HandleUpload | formData: ', formData);
    // for (let [key, value] of formData.entries()) {
    //     console.log(`HandleUpload | formData entry | ${key}: ${value}`);
    // }
    
    try {
        let res = await fetch(hostUrl, {
            method: 'POST',
            // headers: {
            //     'Authorization': `Bearer ${yourAuthToken}`,
            // },
            credentials: 'include', // This sends cookies with the request
            body: formData
        });

        if (!res.ok) {
            throw new Error(`Error: ${res.status} ${res.statusText}`);
        }

        const data = await res.json();
        return data;
    } catch (error) {
        console.error('Error uploading file:', error);
        throw error; // Re-throw the error if necessary
    }



}