import React from 'react'

import "./add.scss"
// import SearchCat from '../../components/SearchCat/SearchCat.jsx';
// import AddForm from './AddForm';
import AddCatSearch from '../AddCatSearch/AddCatSearch';
import AddCatWithColor from '../AddCatWithColor/AddCatWithColor';

const Add = () => {

  return (
    <div className="add-cat-container">
      <AddCatSearch />
      <AddCatWithColor />
    </div>
  )
}

export default Add
