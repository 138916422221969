import axios from "axios";
// import logService from "./logService";
// Handling Unexpected Errors Globally. null because we don't need to log a success. We need to log only errors.

axios.defaults.baseURL = process.env.REACT_APP_API_URL; // - main, but the second option is to have const BASE_URL = process.env.REACT_APP_API_URL in api.js
axios.defaults.withCredentials = true;// !!!!! add credential (cookies) to hadder in HTTP request, otherwise will have a 401 error (unauth)

axios.interceptors.response.use(
    response => response,
    error => {
        const expectedError =
            error.response &&
            error.response.status >= 400 &&
            error.response.status < 500

        if (!expectedError) {
            console.log("Log the error from INTERCEPTOR", error);
            // logService.log(error);
            // alert("An unexpected error occured");
        }

        return Promise.reject(error);
    });

const http = {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    patch: axios.patch,
    delete: axios.delete
}

export default http;