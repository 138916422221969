import React, { createContext, useContext, useEffect, useState } from 'react';
import en from '../trans/en.json';
import ru from '../trans/ru.json';

export const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState('en'); //default language
    // const [content, setContent] = useState({});
    const translations = language === 'en' ? en : ru;

    useEffect(() => {
        const hostname = window.location.hostname;
        // console.log('LanguageProvider hostname = ', hostname)
        if (hostname.includes('catclub.ru')) {
            // console.log('Setting language to Russian');
            setLanguage('ru');
        } else if (hostname.includes('catclub.pro') || hostname.includes('localhost')) {
            setLanguage('en');
        } else {
            setLanguage('en');
        }
    }, []);

    return (
        <LanguageContext.Provider value={{ translations, language, setLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};
