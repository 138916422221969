import React, { useContext, useEffect, useState } from 'react'
// import styled from 'styled-components';
import { fetchCatSalesAll} from '../../services/api';
// import { faMars, faVenus } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CatCardSellSmall from '../CatCardSellSmall/CatCardSellSmall';
import "./sellBlockSmall.scss"
import { Link } from 'react-router-dom';
import { LanguageContext } from "../../context/langContext";

const SellBlockSmall = () => {
    // const [selectedId, setSelectedId] = useState(null);
    // const wrapperRef = useRef(null);
    // console.log('catMiniCard data = ', data)
    // console.log('catMiniCard selectedId = ', selectedId)
    const [itemsToShow, setItemsToShow] = useState(1);
    const [cats, setCats] = useState([]);
    const { translations } = useContext(LanguageContext)

    useEffect(() => {
        const fetchAllCats = async () => {
            try {
                const res = await fetchCatSalesAll() //we made a request to bring us info from front on this http address
                setCats(res); //res.data come already
                // console.log('res all cats =', res);
            } catch (error) {
                console.log(error);
            }
        }
        fetchAllCats()
    }, [])

    // useEffect(() => {
    //     function handleClickOutside(event) {
    //         if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
    //             setSelectedId(null);
    //         }
    //     }

    //     document.addEventListener("mousedown", handleClickOutside);
    //     return () => {
    //         document.removeEventListener("mousedown", handleClickOutside);
    //     };
    // }, [wrapperRef]);

    useEffect(() => {
        const handleResize = () => {
            // console.log("innderWidth = ", window.innerWidth)
            //Since we have a media on 768 with grid 4 colomns, we need to show 8 or 12 items for beauty
            if (window.innerWidth >= 1200) {
                setItemsToShow(12);
            } else if (window.innerWidth >= 1024) {
                setItemsToShow(10);
            } else if (window.innerWidth >= 768) {
                setItemsToShow(8);
            } else {
                setItemsToShow(6);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Set initial value based on current window width

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <div className='sell-block'>
            <div className='sell-block__wrap'>
                {cats?.slice(0, itemsToShow).map((cat, index) => (
                    <CatCardSellSmall key={index} cat={cat} />
                ))}
            </div>
            <div className='sell-block__button_wrap'>
                <Link to="../market"><button className="primary-button-small">{translations.button.buy}</button></Link>
            </div>
        </div>

        // <div ref={wrapperRef} >
        //     {data?.map((item) => {
        //         const isFemale = item.sex === 'female';
        //         const isSelected = item.id === selectedId;
        //         const isThisHovered = item.id === hoveredId; // check if this item is hovered

        //         let currentStyle = isFemale ? cardStyleFemale : cardStyle;
        //         if (isThisHovered) {
        //             currentStyle = {
        //                 ...currentStyle,
        //                 ...(isFemale ? cardHoverStyleFemale : cardHoverStyle)
        //             };
        //         }
        //         if (isSelected) {
        //             currentStyle = { ...currentStyle, ...chosenStyle };
        //         }
        //         // console.log('item.users_id = ', item.users_id);
        //         return (
        //             <div
        //                 style={currentStyle}
        //                 onMouseEnter={() => setHoveredId(item.id)}
        //                 onMouseLeave={() => setHoveredId(null)}
        //                 key={item.id}
        //                 onClick={() => { onClickCat({ catId: item.id, userId: item.users_id }); setSelectedId(item.id) }}
        //             >
        //                 <div style={first}>
        //                     <div style={idStyle}><small>Id: {item.id}</small></div>
        //                     <div style={itemStyle}><b>{item.cat_name_cattery_prefix} {item.cat_name} {item.cat_name_suffix}</b></div>
        //                     {/* <div style={itemStyle}><b>{item.cat_name}</b></div>
        //                     <div style={itemStyle}><b>{item.cat_name_suffix}</b></div> */}
        //                 </div>
        //                 <div style={second}>
        //                     <div style={itemStyle}><em>{item.cat_birthdate}</em></div>
        //                     <div style={emsStyle}>{item.cat_color}</div>
        //                     <div style={emsStyle}>{item.ems}</div>
        //                 </div>
        //                 {/* <div style={firstItemStyle}><small>Id: {item.id}</small></div>
        //                 <div>
        //                     <div style={itemStyle}><b>{item.cat_name_cattery_prefix}</b></div>
        //                     <div style={itemStyle}><b>{item.cat_name}</b></div>
        //                     <div style={itemStyle}><b>{item.cat_name_suffix}</b></div>
        //                 </div>
        //                 <div style={itemStyle}><small>{item.cat_birthdate}</small></div>
        //                 <div style={preLastItemStyle}>{item.cat_color}</div>
        //                 <div style={lastItemStyle}>{item.ems}</div> */}
        //             </div>

        //         )
        //     })}
        // </div>
    );
}

export default SellBlockSmall