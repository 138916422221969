import { useCallback, useEffect, useState } from 'react';
import { useAuth } from '../../context/authContext.js';
// import axios from 'axios';
//My modules//
import "./cats.scss";
import CatCard from "../CatCard/CatCard.jsx";
import Pagination from '../ui/pagination.jsx';
import { paginate } from '../../utils/paginate.js';
import ListGroup from '../ui/ListGroup.jsx'
import { getMyCats } from '../../services/api.js';

const Cats = () => {
    // const [notMyCats, setNotMyCats] = useState([]);
    const [allCats, setAllCats] = useState([]); //an array of my cats
    const [numberOfCats, setNumberOfCats] = useState(0)
    // const [numberOfNotMyCats, setNumberOfNotMyCats] = useState(0)
    const [selectedSexFilter, setSelectedSexFilter] = useState('all')
    const [selectedFilterMy, setSelectedFilterMy] = useState('All')
    const [currentPage, setCurrentPage] = useState(1);
    // const [currentArrayOfCats, setCurrentArrayOfCats] = useState([]);
    // const [pageSize, setPageSize] = useState(5);
    const pageSize = 5;
    // const [filtered, setFiltered] = useState([]);
    const auth = useAuth();
    const currentUserId = auth.currentUser.id;

    const sexFilterNames = ['all', 'female', 'male'];
    const catOwnershipFilterNames = ['All', 'My cats', 'Not my cats'];

    // axios.defaults.withCredentials = true;// !!!!!

    useEffect(() => {
        const fetchDataMyCats = async () => {
            try {
                const resMyCats = await getMyCats(currentUserId)
                setAllCats(resMyCats);
            } catch (error) {
                console.log(error);
            }
        }
        fetchDataMyCats()
    }, [currentUserId])

    // useEffect to apply filters when relevant state changes
    // useEffect(() => {
    //     console.log("Selected Filter: ", selectedFilterMy);
    //     console.log("Selected Sex Filter: ", selectedSexFilter);
    //     console.log("All Cats: ", allMyCats);

    //     let filteredCats = selectedFilterMy === 'My Cats' ? currentArrayOfCats : filterNotMyCats();
    //     console.log("After Ownership Filter: ", filteredCats);

    //     filteredCats = filterByGender(filteredCats);
    //     console.log("After Gender Filter: ", filteredCats);

    //     setCurrentArrayOfCats(filteredCats);
    // }, [selectedFilterMy, selectedSexFilter, allMyCats]);
    // Reapply filters whenever the selected filters change


    const handleFilterSelectMy = useCallback((filterCats) => {
        setSelectedFilterMy(filterCats);
        setCurrentPage(1);
    }, [])

    const handleSexFilterSelect = useCallback((filter) => {
        setSelectedSexFilter(filter);
        setCurrentPage(1);
    }, []);

    const handlePageChange = useCallback((page) => {
        setCurrentPage(page);
    }, []);

    // const applyFilters = (cats) => {
    // let filtered = cats;
    // Filter by ownership
    // if (selectedFilterMy === 'My cats') {
    //     const filtered = allCats.filter(cat => cat.is_my_cat === 1);
    //     setNumberOfCats(filtered.length);
    //     // setCurrentArrayOfCats(filtered);
    //     // Further filter by gender
    //     // if (selectedSexFilter !== 'All') {
    //     //     filtered = filtered.filter(cat => cat.sex === selectedSexFilter);
    //     //     // setNumberOfCats(filtered.length);
    //     //     setCurrentArrayOfCats(filtered);
    //     // }
    // } else if (selectedFilterMy === 'Not my cats') {
    //     const filteredNot = allCats.filter(cat => cat.is_my_cat === 0);
    //     setNumberOfCats(filteredNot.length);
    //     // setCurrentArrayOfCats(filteredNot);
    //     // if (selectedSexFilter !== 'All') {
    //     //     const filteredNot2 = filteredNot.filter(cat => cat.sex === selectedSexFilter);
    //     //     // setNumberOfCats(filtered.length);
    //     //     setCurrentArrayOfCats(filteredNot2);
    //     // }

    // }
    // };

    const filteredCats = allCats.filter(allCats => {
        if (selectedFilterMy === 'My cats' && !allCats.is_my_cat) {
            return false
        }
        if (selectedFilterMy === 'Not my cats' && allCats.is_my_cat) {
            return false
        }
        if (selectedSexFilter !== 'all' && allCats.sex !== selectedSexFilter) {
            return false;
        }
        return true
    })

    // useEffect(() => {
    //     setNumberOfCats(filteredCats.length)
    // }, [filteredCats])
    // setNumberOfCats(filteredCats.length)
    useEffect(() => {
        if (selectedFilterMy === 'Not my cats') {
            // console.log('Not my cats')
            const numb = allCats.filter(cat => cat.is_my_cat === 0).length;
            // console.log('numb = ', numb);
            setNumberOfCats(numb)
            // numb ? (setNumberOfCats(numb)) : (setNumberOfCats(0));

        } else if (selectedFilterMy === 'My cats') {
            setNumberOfCats(allCats.filter(cat => cat.is_my_cat === 1).length);
        } else setNumberOfCats(allCats.length)
    }, [allCats, selectedFilterMy])

    // console.log('filteredCats = ', filteredCats)

    // const filtered = selectedSexFilter !== 'All'
    //     ? currentArrayOfCats.filter(m => m.sex === selectedSexFilter) //we take an array of cats and filter them according to sex
    //     : currentArrayOfCats;
    // const filtered = selectedFilter !== 'All'
    //     ? allMyCats.filter(m => m.sex === selectedFilter)
    //     : allMyCats;

    const paginatedCats = paginate(filteredCats, currentPage, pageSize); //paginate function returns an array of cats

    return (
        <div className="cats-container">
            <h1>All cats</h1>
            <div className="cats__upperBlock">
                <ListGroup numberOfCats={numberOfCats} selectedFilter={selectedFilterMy} onFilterSelect={handleFilterSelectMy} items={catOwnershipFilterNames} />
                <ListGroup numberOfCats={numberOfCats} selectedFilter={selectedSexFilter} onFilterSelect={handleSexFilterSelect} items={sexFilterNames} />
            </div>
            <div className="catblock__row">
                {paginatedCats?.map((catt) => (
                    <CatCard
                        id={catt.id}
                        key={catt.id}
                        cat_name={catt.cat_name}
                        registered={catt.registered}
                        cat_name_cattery_prefix={catt.cat_name_cattery_prefix}
                        cat_name_suffix={catt.cat_name_suffix}
                        sex={catt.sex}
                        cat_birthdate={catt.cat_birthdate}
                        users_id={catt.users_id} cover={catt.cat_ava_url}
                        is_my_cat={catt.is_my_cat} />
                ))}
            </div>
            <Pagination
                itemsCount={filteredCats.length}
                numberOfCats={numberOfCats}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={handlePageChange} />
            {/* <Pagination
                itemsCount={filtered.length}
                numberOfCats={numberOfCats}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={handlePageChange} /> */}
            {/* total numbers of items and page size. Based on this information the component can calculate the number of pages and render them*/}
        </div>
    )
}

export default Cats
