import { Link, useLocation } from "react-router-dom"
// import { useEffect } from 'react';
// import { useState } from 'react';
// import axios from 'axios';
import MainPhoto from "../../../src/img/mainphoto1.jpg";
import MainPhoto2 from "../../../src/img/mainphoto2.jpg";
import MainPhoto3 from "../../../src/img/mainphoto3.jpg";

// import { useContext } from 'react'
//============
import "./partners.scss"
import { useAuth } from "../../context/authContext";
import { useContext } from "react";
import { LanguageContext } from "../../context/langContext";
// import { useAuth } from '../../context/authContext.js';

const Partners = () => {
  const mainphoto1 = '/images/mainphoto1.jpg'
  const { translations } = useContext(LanguageContext)

  //For Yandex.metric
  const location = useLocation();
  if (window.location.hostname !== 'localhost') {
    window.ym(95428596, 'hit', location.pathname);
  }
  // const [cats, setCats] = useState([]);

  // const { currentUser } = useContext(useAuth);
  // const auth = useAuth();

  // useEffect(() => {
  //   const fetchAllCats = async () => {
  //     try {
  //       const res = await axios.get("http://localhost:8800/api/cats") //we made a request to bring us info from front on this http address
  //       setCats(res.data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  //   fetchAllCats()
  // }, [])
  const auth = useAuth();
  const currentUser = auth.currentUser;

  return (
    <main className='partners'>
      <section className="main-block">
        <div className="img-container">
          <img src={MainPhoto || mainphoto1} alt="Woman with a cat is looking at a laptop "></img>
          <div className="text-overlay">
            <div className="cattery-with-breeder">
              {/* <div className="cattery-with-breeder__names"> */}
              <h2>{translations.partners.header.h1}</h2>
              <div className="cattery-with-breeder__text">
                <p>{translations.partners.description.d1}</p>
              </div>
              {currentUser ? (
                <>
                </>
              ) : (
                <>
                  <Link to="../join"><button className="joinButton">{translations.auth.join_us}</button></Link>
                </>
              )}
              {/* </div> */}
            </div>
          </div>
        </div>
      </section>
      <section className="main-block">
        <div className="img-container">
          <img src={MainPhoto3} alt="Woman with a cat"></img>
          <div className="text-overlay">
            <div className="cattery-with-breeder">
              <h2>{translations.partners.header.h2}</h2>
              <p>{translations.partners.description.d2}</p>
              <p>{translations.partners.description.d3}</p>
              {currentUser ? (
                <>
                </>
              ) : (
                <>
                  <Link to="../join"><button className="joinButton">{translations.auth.join_us}</button></Link>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="main-block">
        <div className="img-container">
          <img src={MainPhoto2} alt="Woman kissing a cat"></img>
          <div className="text-overlay">
            <div className="cattery-with-breeder">
              <h2>{translations.partners.header.h3}</h2>
              <p>{translations.partners.description.d4}</p>
              <div>
                {currentUser ? (
                  <>
                  </>
                ) : (
                  <>
                    <Link to="../join"><button className="joinButton">{translations.auth.join_us}</button></Link>
                  </>
                )}
              </div>
              <div className="cattery-with-breeder__text">
                <p>{translations.partners.description.d5}</p>
              </div>
              <p>{translations.partners.description.nfc_club}</p>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}


export default Partners
