

import { Link, useRouteError } from "react-router-dom";
import './errorPageNotAuth.scss';


export default function ErrorPageNotAuth() {
  const error = useRouteError();
  console.log(error);

  return (
    <div className="not-found-container">
      <h1>Oops!</h1>
      <h2>
        {/* <p>
          <i>{error.statusText || error.message}</i>
        </p> */}
      </h2>
      {/* <p>Oops! </p> */}
      <p>It looks like you've reached an area where special permission is required.</p>
      <p>Maybe you tried to reach a cat page which belong to other user.</p>
      <Link to={`/`}>Go Home</Link>
    </div>
  );
}