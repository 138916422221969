import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthContextProvider } from './context/authContext.js';
import App from './App';
// import logService from './services/logService.js';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';

///// styles /////
import "./styles/global.scss";

// Import the 'dotenv' module to load environment variables
// import dotenv from 'dotenv';

// Load environment variables from the .env file
// dotenv.config();

// logService.init();

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  </React.StrictMode>
);

// let mainTitle = 'Cat Club - Клуб кошек';
// // let mainTitle;
// if (window.location.hostname.includes('catclub.ru') || window.location.hostname.includes('localhost')) {
//   mainTitle = 'Cat Club - Клуб кошек';
// } else if (window.location.hostname.includes('catclub.pro')) {
//   mainTitle = 'Cat Club - The Premier Community for Cat Lovers';
// }
// document.title = mainTitle
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
