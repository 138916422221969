
import { Link, useRouteError } from "react-router-dom";
import './error-page.scss';


export default function ErrorPage() {
  const error = useRouteError();
  console.log(error);

  return (
    <div className="not-found-container">
      <h1>404</h1>
      {/* <h2>
        <p>
          <i>{error.statusText || error.message}</i>
        </p>
      </h2> */}
      <p>Sorry, an unexpected error has occurred.</p>
      <p>The page you're looking for doesn't exist.</p>
      <Link to={`my`}>Go Back Home</Link>
    </div>
  );
}