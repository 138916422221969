import _ from 'lodash';

export function paginate(items, pageNumber, pageSize) {
    const startIndex = (pageNumber - 1) * pageSize;
    //  _.slice(items, startIndex)
    // _.take()
    //lodash object needs to be converted to regular array
    return _(items)
        .slice(startIndex)
        .take(pageSize)
        .value();
}