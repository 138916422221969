
import "./otherTab.scss";
// import { CatContext } from '../../../context/CatContext';
// import { useContext } from "react";

const OtherTab = () => {

    // const { cat } = useContext(CatContext);

    return (
        <div className='cat-profile__parents'>
            <div className='text'><span>Chip </span></div>
        </div>
    )
}

export default OtherTab




