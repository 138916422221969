import { useContext, useEffect, useState } from 'react';
import { useAuth } from '../../context/authContext.js';

//My modules//
import "./profile.scss";
import Cats from "../../components/Cats/Cats.jsx";
import formatDateFromUTC from "../../utils/formatDateFromUTC.js";
import TabView from "../../components/ui/TabView/TabView.jsx";
import { CatteryTab } from "./CatteryTab/CatteryTab.jsx";
import UserTab from "./UserTab/UserTab.jsx";
import { getMyCats, fetchMyCatteries, fetchUser } from '../../services/api.js'; // Import the API function
import { Link } from 'react-router-dom';
import { LanguageContext } from "../../context/langContext.js";
import MySell from '../../components/MySell/MySell.jsx';
import SellOrder from '../../components/SellOrder/SellOrder.jsx'
import BuyOrder from '../../components/BuyOrder/BuyOrder.jsx';

export const Profile = () => {
  const { translations } = useContext(LanguageContext)

  const [user, setUser] = useState({
    about: "",
    birthday: "",
    first_name: "",
    last_name: "",
    middle_name: "",
    user_ava_url: "",
    site_url: "",
    en: "",
    ru: ""
  });
  // console.log('user = ', user)
  const [cattery, setCattery] = useState([]);
  const [numberOfCats, setNumberOfCats] = useState(null)

  const auth = useAuth();
  const currentUserId = auth.currentUser.id;

  // console.log('Profile auth = ', auth)
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the current user based on the currentUserId
        const dataUser = await fetchUser();
        // console.log('Profile | dataUser = ', dataUser)
        // const res = await http.get(`${BASE_URL}/users/${currentUserId}`) //we made a request from front on this http address to bring us info from server
        setUser({ ...dataUser, birthday: formatDateFromUTC(dataUser.birthday) })
        const resMyCats = await getMyCats(false) //userId came from midleware from {userInfo} in controller
        // console.log("Profile | resMyCats = ", resMyCats)
        const countCats = resMyCats.length;
        setNumberOfCats(countCats);
        const resMyCatteries = await fetchMyCatteries(currentUserId) //we made a request from front on this http address to bring us info from server
        setCattery(resMyCatteries.data);

      } catch (error) {
        console.log(error);
        console.error('Error fetching user by ID:', error);
        throw error;
      }
    }
    fetchData()
  }, [currentUserId])

  // console.log('user_ava_url = ', process.env.PUBLIC_URL + user.user_ava_url);
  const [activeProfile, setActiveProfile] = useState('profile1');

  const AllMyCats = () => {
    return (
      <>
        {numberOfCats ? (
          <Cats />
        ) : (
          <>
            <p>{translations.my.text_no_cats}</p>
            <Link className='header__link' to={`/add`}>
              <button className="joinButton">{translations.my.add_a_cat}</button>
            </Link>
          </>
        )}
      </>
    )
  }


  const renderComponent = () => {
    switch (activeProfile) {
      case 'profile1':
        return <AllMyCats />;
      case 'MySell':
        return < MySell />;
      case 'Sell Orders':
        return <SellOrder />;
      case 'Buy Orders':
        return <BuyOrder />;
      default:
        return <AllMyCats />;
    }
  };

  return (
    <div className="profile">

      {/* <TabView tabs={[
        { name: `${translations.my.user}`, content: <UserTab user={user} currentUserId={currentUserId} /> },
        { name: `${translations.my.cattery}`, content: <CatteryTab cattery={cattery} /> }
      ]} /> */}
      <TabView tabs={[
        { name: `${translations.my.user}`, content: <UserTab user={user} numberOfCats={numberOfCats} currentUserId={currentUserId} /> },
        { name: `${translations.my.cattery}`, content: <CatteryTab cattery={cattery} /> }
      ]} />
     
      <div>
        <button className="button" onClick={() => setActiveProfile('profile1')}>{translations.my.all_my_cats}</button>
        <button className="button" onClick={() => setActiveProfile('MySell')}>{translations.my.sell}</button>
        <button className="button" onClick={() => setActiveProfile('Sell Orders')}>{translations.my.sell_orders}</button>
        <button className="button" onClick={() => setActiveProfile('Buy Orders')}>{translations.my.buy_orders}</button>

        {renderComponent()}
      </div>
      {/* {numberOfCats ? (
        <Cats />
      ) : (
        <>
          <p>
            You don't have any cats here right now! Add your perfect cats and create a cat page!
          </p>
          <Link className='header__link' to={`/add`}>
            <button className="joinButton">
              {translations.my.add_a_cat}
            </button>
          </Link>

        </>
      )} */}

    </div>
  )
}
