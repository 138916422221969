import React, { useState, useEffect, useContext } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import Select from 'react-select';
//MY MODULES//
import { useAuth } from '../../../context/authContext.js';
import FormInput from '../../../components/FormInput/FormInput';
import { patchCat } from '../../../services/api';
import { CatContext, CatContextProvider } from '../../../context/CatContext.js';
// import axios from 'axios';
const Update = () => {
    // const urlParts = window.location.pathname.split('/');
    // console.log('urlParts = ', urlParts)
    // const catId = urlParts[urlParts.length - 2];
    const { id: catId } = useParams(); // Grabs catId from the URL
    // console.log("EditCatProfile | catId", catId)
    return (
        <CatContextProvider catId={catId}>
            <UpdateChild catId={catId} />
        </CatContextProvider>
    );
}
const UpdateChild = () => {
    const { data: cat } = useContext(CatContext);
    console.log('UpdateChild | catContext, cat: ', cat)
    const auth = useAuth() //auth.currentUser from /context/authContext.js
    const location = useLocation();
    const navigate = useNavigate()
    const catId = location.pathname.split("/")[2] //http://localhost:3001/post/1 - we take post number from URL address in browser

    const [values, setValues] = useState({
        id: "",
        description: "",
        chip: "",
    });

    const onChange = (e) => {
        setValues((prev) => ({ ...prev, [e.target.name]: e.target.value, users_id: auth.currentUser.id }))
    };

    const inputs = [
        {
            id: "description",
            name: "description",
            type: "textarea",
            label: "Cat description",
            placeholder: "Cat description",
            onChange: onChange,
            maxLength: 100,
            required: false,
            value: values?.description || ""
        },
        {
            id: "chip",
            name: "chip",
            type: "text",
            label: "Chip",
            placeholder: "Chip",
            errorMessage: "Use 3-100 letters or numbers",
            pattern: "^[А-Яа-яA-Za-z0-9 ]{3,100}$",
            required: false,
            onChange: onChange,
            maxLength: 100, // limit to 100 characters
            value: values?.chip || ""
        }
    ]


    //fetch Cat info from DB and store in state 'cat'
    useEffect(() => {
        const fetchData = async () => {
            try {
                // const resData = await fetchOneCat(catId); // return already res.data
                // console.log('res.data from fetchOneCat', res.data)
                // setValues(resData);
                // const formattedDate = formatDateFromDB(cat?.cat_birthdate)
                // setValues((prev) => ({ ...prev, cat_birthdate: formattedDate }))
                if (cat) {
                    setValues(() => ({
                        id: cat?.id,
                        // cat_birthdate: formattedDate,
                        description: cat?.description || "",
                        chip: cat?.chip || ""
                        // cat_birthdate: cat?.cat_birthdate_input || "",
                        // breeder_id: currentBreederSelect?.value,
                        // currentBreedSelect: currentCatBreed || "",
                        // breederSelect: currentBreederSelect,

                    }))
                }
            } catch (err) { console.log(err) }
        }
        fetchData();
    }, [cat])

    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log(values);
        // console.log(breeder.id);
        // console.log("color before sinding", newColor)
        try {
            // Use Promise.all to send multiple requests concurrently
            await Promise.all([
                patchCat(values), // This function updates the cat data
            ]);

            // Both requests completed successfully
            navigate(`/cats/${values.id}`);
        } catch (error) {
            console.log(error)
        }
    }

    const cancelEdit = () => {
        navigate(`/cats/${catId}`)
    }

    return (
        <div className="cat-edit-container">
            <div className="cat-edit">
                <form
                    onSubmit={handleSubmit}>
                    <h2>Edit Cat Profile - ID{values.id}</h2>
                    <p>Your cat is registered - you can edit only limited information.</p>
                    {inputs.map((input) => (
                        <FormInput
                            key={input.id}
                            {...input}
                        // onChangeSelect={input.onChangeSelect}
                        // options={input.options}
                        // value={values[input.name] || ''}
                        // onChange={onChange}
                        // inputId={input.inputId} 
                        />
                    ))}
                    <button className='joinButton'>Send</button>
                    <button type='button' className='button-light' onClick={cancelEdit}>Cancel</button>
                </form>
            </div>
        </div>
    )
}

export default Update
