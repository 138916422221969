import { useTranslations } from "../../../../hooks/useTranslations";
import { useContext, useState } from "react";
import { CatContext } from "../../../../context/CatContext";
import { sendToSell } from "../../../../services/api";
// import { SaleInformationOneCatContext } from "../../../context/SaleInformationOneCatContext";
// import { useNavigate } from "react-router-dom";
import formatDateFromUTC from "../../../../utils/formatDateFromUTC";
// import { useNavigate } from "react-router-dom";



export const SellingEdit = ({ initialData, onSave, onCancel }) => {
    const { translations, language } = useTranslations();
    // console.log('SellingEdit | initialData: ', initialData)
    // const navigate = useNavigate();  // Initialize useNavigate
    const data = useContext(CatContext);
    // const sellData = useContext(SaleInformationOneCatContext);
    // console.log("SellingEdit | SaleInformationOneCatContext | sellData = ", sellData)
    // console.log("SellingEdit | SaleInformationOneCatContext | sellData.data.data[0] = ", sellData.data.data[0])
    // const sellingInformation = sellData.data.data[0]
    // const [active, setActive] = useState(false)
    const cat = data.data;
    // console.log('cat = ', cat)
    // const [sellData, setSellData] = useState('')
    // const [formValues, setFormValues] = useState({ initialData });
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [formValues, setFormValues] = useState({
        cat_id: cat?.id || '',
        price_pet: initialData?.price_pet || '',
        price_breeding: initialData?.price_breeding || '',
        sale_for_breeding: initialData?.sale_for_breeding || false,
        delivery_text: initialData?.delivery_text || '',
        ready_to_move_at: initialData?.ready_to_move_at || '',
        ready_to_move_at_input: initialData?.ready_to_move_at_input || '',
        currency_abb: initialData?.currency_abb || '',
        is_active: initialData?.is_active || false
    });
    // const [formValues, setFormValues] = useState({
    //     cat_id: cat?.id || '',
    //     price_pet: sellingInformation?.price_pet || '',
    //     price_breeding: sellingInformation?.price_breeding || '',
    //     sale_for_breeding: sellingInformation?.sale_for_breeding || false,
    //     delivery_text: sellingInformation?.delivery_text || '',
    //     ready_to_move_at: sellingInformation?.ready_to_move_at_input || '',
    //     readyDateInput: sellingInformation?.ready_to_move_at_input || '',
    //     currency_abb: sellingInformation?.currency_abb || '',
    //     is_active: sellingInformation?.is_active || false
    // });

    // useEffect(() => {
    //     const fetchSellingInformation = async () => {
    // try {
    //     const response = await fetchSellingInformationById(cat.id);
    //     // console.log('response = ', response)
    //     if (response.status === 'success' && response.data.length > 0) {
    //         const sellingData = response.data[0];
    //         console.log('sellingEditChild | sellingData = ', sellingData)
    //         // Convert date to YYYY-MM-DD format
    //         const formattedReadyDate = formatDateFromDB(sellingData.ready_to_move_at)
    //         // console.log('formattedReadyDate', formattedReadyDate)
    //         setFormValues({
    //             catId: sellingData?.cat_id,
    //             priceWithoutBreeding: sellingData?.price_pet,
    //             priceWithBreeding: sellingData?.price_breeding || "",
    //             deliveryOption: sellingData?.delivery_text || "",
    //             readyDate: formattedReadyDate, //element typically expects the date in the format YYYY-MM-DD.
    //             isBreedingAllowed: sellingData?.sale_for_breeding || false,
    //             currency_abb: cat?.currency_abb,
    //             // currency_id: cat?.currency_id,
    //             is_active: sellingData.is_active || false
    //         });
    //         // setActive(sellingData.is_active === 1);
    //     } else if (response.status === 'no_data') {
    //         console.log('No data:', response.message);
    //         // Handle no data found case
    //         // setSellData('no data')
    //     } else {
    //         console.log('Error:', response.message);
    //     }
    // } catch (error) {
    //     console.error('Fetch error:', error);
    //     // alert('An error occurred while fetching the data.');
    // }
    // };

    // fetchSellingInformation()

    // }, [cat?.currency_abb, cat?.currency_id, cat.id])

    // const handleToggle = () => {
    //     setActive(!active);
    //     setFormValues((prevValues) => ({
    //         ...prevValues,
    //         is_active: !active ? 1 : 0
    //     }));
    // };

    //6 numbers are allowed
    const validatePrice = (value) => {
        const regex = /^\d{0,6}$/;
        return regex.test(value);
    };

    function convertToUTC(dateInput) {
        // Create a Date object from the input (assumes time is at 00:00:00 in the local time zone)
        const localDate = new Date(`${dateInput}T00:00:00`);

        // Get the UTC date-time string
        const utcDate = new Date(localDate.getTime() - (localDate.getTimezoneOffset() * 60000)).toISOString();

        return utcDate;
    }

    const handleChange = (e) => {
        // e.preventDefault();
        let formattedDate;
        let beautyDate;
        const { name, value, type, checked } = e.target;
        if ((name === "price_pet" || name === "price_breeding") && !validatePrice(value)) {
            return;
        }
        if (e.target.name === "ready_to_move_at_input") {
            console.log("e.target.value:", e.target.value)
            const utcDate = convertToUTC(e.target.value)
            console.log('utcDate: ', utcDate)
            // ----- convert from UTC to 12 May 2024 ----- //
            beautyDate = formatDateFromUTC(utcDate)
            console.log('beautyDate: ', beautyDate)
            // ------ convert from 2024-MM-dd to 12 May 2024 ------ // 
            // ------ TimeZONE! in input the timezone is set to YYYY-mm-dd no time, but Date object set up it to midnigh ----- //
            // When you create a Date object using new Date(e.target.value), JavaScript interprets "2024-08-26" as "2024-08-26T00:00:00" in the local time zone.

            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            // formattedDate = new Date(e.target.value).toLocaleDateString('en-GB', options);
            const [year, month, day] = e.target.value.split('-');
            const date = new Date(Date.UTC(year, month - 1, day));
            console.log('UTC date:', date)
            formattedDate = date.toLocaleDateString('en-GB', options);
            console.log('formattedDate:', formattedDate)

            // Combine date with default time '00:00:00'
            const dateTimeString = `${e.target.value}T00:00:00`;

            // Create a Date object in the user's local time zone
            const localDate = new Date(dateTimeString);
            console.log('locatlDate:', localDate)
            // Get the time zone offset in hours and minutes
            const offsetMinutes = localDate.getTimezoneOffset();
            const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
            const offsetSign = offsetMinutes > 0 ? '-' : '+';
            const offsetString = `${offsetSign}${String(offsetHours).padStart(2, '0')}:${String(Math.abs(offsetMinutes) % 60).padStart(2, '0')}`;

            // Format the date string to include the time zone offset
            const localDateWithOffset = `${localDate.toISOString().slice(0, 10)}T00:00:00${offsetString}`;

            // Here you can handle the localDateWithOffset, e.g., send it to your server or store it
            console.log('Local date with time zone:', localDateWithOffset);
        }
        // setFormValues((prevValues) => ({
        //     ...prevValues,
        //     ready_to_move_at: formattedDate,
        //     [name]: type === 'checkbox' ? checked : value,
        // }));
        setFormValues((prevValues) => {
            // Check if the current field being updated is 'ready_to_move_at'
            if (e.target.name === 'ready_to_move_at_input') {
                console.log("setFormValues | e.target.value:", e.target.value)
                // Return the updated form values with the formatted date
                return {
                    ...prevValues,
                    ready_to_move_at: beautyDate,
                    ready_to_move_at_input: e.target.value
                };
            }

            // Otherwise, return the updated form values without formatting
            return {
                ...prevValues,
                [name]: type === 'checkbox' ? checked : value
            };
        });


    };


    // console.log('formValues:', formValues)

    const handleSubmit = async () => {
        // Basic form validation
        setIsSubmitting(true)
        if (!formValues.price_pet || !formValues.delivery_text || !formValues.ready_to_move_at_input) {
            alert('Please fill out all required fields.');
            setIsSubmitting(false);  // Reset to false if there's an issue
            return;
        }
        // ----- Values for sending to Server ----- //
        const valuesToSend = {
            cat_id: formValues.cat_id,
            price_pet: formValues.price_pet,
            price_breeding: formValues.price_breeding,
            sale_for_breeding: formValues.sale_for_breeding,
            delivery_text: formValues.delivery_text,
            ready_to_move_at: formValues.ready_to_move_at_input,
            // ready_to_move_at_input: formValues.ready_to_move_at_input,
            is_active: formValues.is_active
        }
        console.log("SellingEdit | before sending to server | valuesToSend: ", valuesToSend);

        try {
            const data = await sendToSell(valuesToSend);
            console.log("Form Data Submitted: ", data);
            if (data?.success) {
                console.log('formValues before onSave: ', formValues)
                onSave(formValues);  // Pass the updated data back to the parent
            }
            // Handle successful submission, e.g., show a success message or redirect
        } catch (error) {
            console.error("Error submitting form: ", error);
            // alert(error.message); // Show error message to the user
        } finally {
            setIsSubmitting(false);
        }
    }

    return (
        <section className="selling-tab__form">
            {/* {sellData === 'no data' && 'No selling data'} */}
            {/* <div className="toggle-switch">
                <input
                type="checkbox"
                id="toggle"
                className="toggle-switch__checkbox"
                checked={active}
                onChange={handleToggle}
                />
                <label
                htmlFor="toggle"
                className={`toggle-switch__label ${active ? 'active' : ''}`}
                ></label>
                </div> */}
            {/* <div>
                {active === true ? translations.cat_profile.active : ''}
                </div> */}
            {(!cat?.country_en || !cat?.country_ru) && (
                <div>
                    {/* <div>{translations.country}</div> */}
                    <div>
                        <label htmlFor="country">{translations.country}</label>
                        <input
                            id="country"
                            type="select"
                            name="country"
                            value={formValues.country}
                            onChange={handleChange} />
                        {cat?.currency_abb}
                    </div>
                </div>)}

            <div className="">
                {translations.country}: {language === 'en' ? cat?.country_en : cat?.country_ru}
            </div>

            <div>
                <label htmlFor="price_pet">{translations.cat_profile.price_pet}</label>
                <input
                    id="price_pet"
                    type="text"
                    name="price_pet"
                    value={formValues.price_pet}
                    onChange={handleChange} />
                {cat.currency_abb}
                {/* </div> */}
            </div>
            <div>
                <input
                    type="checkbox"
                    name="sale_for_breeding"
                    checked={formValues.sale_for_breeding}
                    onChange={handleChange} />
                <label>{translations.cat_profile.sale_for_breeding}</label>
            </div>
            <div>
                <label htmlFor="price_breeding">{translations.cat_profile.price_breeding}</label>
                <input
                    type="text"
                    id="price_breeding"
                    name="price_breeding"
                    value={formValues.price_breeding}
                    onChange={handleChange} />
                {cat.currency_abb}
            </div>
            <h4>{translations.cat_profile.delivery}</h4>
            <div className="selling__textarea">
                <label htmlFor="delivery_text">{translations.cat_profile.delivery_text}</label>
                <textarea rows="3" cols="24"
                    id="delivery_text"
                    name="delivery_text"
                    value={formValues.delivery_text}
                    onChange={handleChange}>
                </textarea>
            </div>
            <div>
                <label htmlFor="ready_to_move_at_input">{translations.cat_profile.ready_date}</label>
                <input
                    type="date"
                    id="ready_to_move_at_input"
                    name="ready_to_move_at_input"
                    value={formValues.ready_to_move_at_input}
                    onChange={handleChange} />
            </div>
            <div>
                <input
                    type="checkbox"
                    name="is_active"
                    checked={formValues.is_active} //formValues.is_active should be the same as name, hence 'is_active'
                    onChange={handleChange} />
                <label>{translations.cat_profile.active}</label>
            </div>
            <div>
                <button
                    className="primary-button-small"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? 'Saving...' : 'Save'}
                </button>
                {/* <button className="button-light" onClick={onCancel} disabled={isSubmitting}>
                    Cancel
                </button> */}
            </div>
            {/* {!isSent &&
                <button className="primary-button-small" onClick={handleSubmit}>{translations.cat_profile.send}</button>
            } */}
            {/* <div>
                {isSent ? (
                    <div className="sent">
                        <span>SENT</span>
                        {/* <button className="button-light-main" onClick={handleGoToProfile}> Go to cat profile</button> */}
            {/* </div> */}
            {/* // ) : ('')} */}
            {/* // </div> */}
        </section>
    )
}

