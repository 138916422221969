import { Link, useNavigate } from "react-router-dom";
import Metrika from '../../utils/Metrika.js'
// import { YMInitializer } from 'react-yandex-metrika';
// import { useContext } from 'react'
//===========
import "./header.scss";
import Logo from "../../img/logo-nfc.png";
import { useAuth } from "../../context/authContext";
import { LanguageContext } from '../../context/langContext.js';
import { useContext, useEffect, useState } from "react";

// import { AuthContextProvider} from '../../context/authContext.js';

// import Join from "./pages/Join";
// import Cats from "./pages/Cats";
// import Add from "./pages/Add";
// import Update from "./pages/Update";
import { DropdownMenu } from '../ui/DropdownMenu';

// const path = `${process.env.PUBLIC_URL}/client/src/img/logo-nfc.png`
// const path = process.env.PUBLIC_URL + "/client/src/img/logo-nfc.png";

const Header = () => {
  const { setLanguage } = useContext(LanguageContext);
  const { translations } = useContext(LanguageContext)
  // const { currentUser, logout } = useContext(AuthContextProvider);
  const auth = useAuth();
  const navigate = useNavigate();
  const currentUser = auth.currentUser;

  const [headerWidth, setHeaderWidth] = useState('');

  useEffect(() => {
    const width = window.innerWidth;
    // console.log('width = ', width)
    setHeaderWidth(width);
    // if (width < 480) {
    //     setHeader('Мобильный заголовок');
    // } else if (width < 768) {
    //     setHeader('Планшетный заголовок');
    // } else {
    //     setHeader('Десктопный заголовок');
    // }

    //   // window.addEventListener('resize', handleResize);
    //   // handleResize();  // вызов при монтировании компонента

    //   // return () => {
    //   //     window.removeEventListener('resize', handleResize);
    // };
  }, []);

  const handleLogout = () => {
    navigate('/');
    auth.logout();
  };


  if (currentUser && (headerWidth < 480)) {
    return (
      <header className="header-wrap">
        <div className="wraplogo">
          <Link to="/"><img alt="logo" className="logo" src={Logo} /></Link>
        </div>
        <Link className="header__link" to="../market"> {translations.header?.sale}</Link>
        <Link className="header__link" to="../favorites"> {translations.header?.favorites} </Link>
        <Link className="header__link" to="../my">{translations.header?.profile}</Link>
        <DropdownMenu currentUserId={currentUser.id} which={'header'} />
        <Metrika />
      </header >
    )
  } else if (currentUser && (headerWidth >= 480)) {
    return (
      <header className="header-wrap">
        <div className="wraplogo">
          <Link to="/"><img alt="logo" className="logo" src={Logo} /></Link>
        </div>
        <div className="language-wrap">
          <button onClick={() => setLanguage('en')}>English</button>
          <button onClick={() => setLanguage('ru')}>Русский</button>
        </div>
        <Link className="header__link" to="../market">{translations.header?.sale} </Link>
        <Link className="header__link" to="../favorites">{translations.header?.favorites} </Link>
        <Link className="header__link" to={`add`}>{translations.header?.add_cat} </Link>
        <Link className="header__link" to={`my`}> {currentUser.first_name} </Link>
        <Link className="header__link" onClick={handleLogout}>{translations.header?.logout} </Link>
        <Metrika />
      </header >
    )

  } else {
    return (
      <header className="header-wrap">
        <div className="wraplogo">
          <Link to="/"><img alt="logo" className="logo" src={Logo} /></Link>
        </div>
        <div className="language-wrap">
          <button onClick={() => setLanguage('en')}>Eng</button>
          <button onClick={() => setLanguage('ru')}>Рус</button>
        </div>
        <Link className="header__link" to="../market">{translations.header?.sale}</Link>
        <Link className="header__link" to="../Login">{translations.header?.login}</Link>
        <Link className="header__link" to="../Join"> {translations.auth?.join}</Link>
        {/* <Link className="header__link" to={`my`}> {currentUser.first_name} </Link>
        <Link className="header__link" to={`add`}>{translations.header?.add_cat}</Link>
        <Link className="header__link" onClick={handleLogout}>{translations.header?.logout}</Link> */}
        <Metrika />
      </header >

    )
  }
}
export default Header;
