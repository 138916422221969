import React, { useContext, useState } from 'react'
// import styled from 'styled-components';
import { faMars, faVenus, faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LanguageContext } from "../../context/langContext";
import { Link } from 'react-router-dom';

const CatCardSellSmall = ({ index, cat }) => {
    const catAva = '/images/catAva.jpg'
    const [isHovered, setIsHovered] = useState(false);
    const [isHoveredCard, setIsHoveredCard] = useState(false);
    const [isAddedToFavorite, setIsAddedToFavorite] = useState(false);
    // console.log('{cat} =', cat)
    const { language } = useContext(LanguageContext)
    const catId = cat.id;
    const styles = {

        catCard: {
            // maxWidth: '170px', /* Container takes full width of its parent */
            overflow: 'hidden',
            cursor: isHoveredCard ? 'pointer' : 'default',
            border: '1px solid lightgray',
            transition: 'box-shadow 0.15s ease-in-out',
            // margin: '0 auto',
            boxShadow: isHoveredCard ? '0 0 7px rgba(0, 0, 0, 0.4)' : 'none'
        },
        catAvaRelative: {
            position: 'relative',
        },
        catCardImg: {
            width: '100%', /* Image takes full width of container */
            objectFit: 'cover',
            height: 'auto'
        },
        iconMarsWrap: {
            border: '1px solid rgba(0, 0, 0, 0)',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            padding: '5px',
            position: 'absolute',
            top: '0',
            right: '0px',
        },
        iconHeartWrap: {
            // backgroundColor: 'rgba(0, 0, 0, 0.1)',
            padding: '5px',
            position: 'absolute',
            bottom: '3px',
            right: '0px',
        },

        // Nested styles can be handled separately
        iconMars: {
            color: 'rgba(36, 177, 233, 1)',
            fontSize: '1.1em',
            width: '24px',
            height: '24px'
        },
        iconVenus: {
            color: 'rgba(245, 138, 222, 1)',
            fontSize: '1.1em',
            width: '24px',
            height: '24px'
        },
        iconHeart: {
            color: isAddedToFavorite ? 'rgb(255, 138, 225)' : (isHovered ? 'rgba(245, 138, 222, 0.8)' : 'rgba(255, 255, 255, 0.7)'),
            fontSize: '1.1em',
            // backgroundColor:'pink',
            width: '24px',
            height: '24px',
            cursor: isHovered ? 'pointer' : 'default',
            transition: 'color 0.15s ease-in-out'
        },
        text: {
            backgroundColor: 'white',
            textAlign: 'center',
            padding: '0.8em 0'
        }
    };
    const OnClickFavorite = () => {
        if (!isAddedToFavorite) {
            console.log('Added to Favorite')
            setIsAddedToFavorite(true);
        } else {
            console.log('Delited to Favorite')
            setIsAddedToFavorite(false);
        }
    }

    return (
        <div>
            <div key={index} style={styles.catCard}
                onMouseEnter={() => setIsHoveredCard(true)}
                onMouseLeave={() => setIsHoveredCard(false)}
            >
                <div style={styles.catAvaRelative}>
                    {cat?.cat_ava_url ? (
                        <Link className='nolink' to={`/cat-profile-sell/${catId}`}>
                            <img style={styles.catCardImg}
                                src={cat?.cat_ava_url ? cat?.cat_ava_url : catAva}
                                alt={cat?.cat_ava_url ? ('Photo of ' + cat?.cat_name) : ('catAva')} />
                        </Link>
                    ) : (
                        <Link className='nolink' to={`/cat-profile-sell/${catId}`}>
                            <img style={styles.catCardImg} src={catAva} alt="catAva" />
                        </Link>
                    )}

                    <div style={styles.iconMarsWrap}>
                        {cat?.sex === "male" ? (
                            <FontAwesomeIcon icon={faMars} style={styles.iconMars} />
                        ) : (
                            <FontAwesomeIcon icon={faVenus} style={styles.iconVenus} />
                        )}
                    </div>
                    <div
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        onClick={OnClickFavorite}
                        style={styles.iconHeartWrap}>
                        <FontAwesomeIcon icon={faHeart} style={styles.iconHeart} />
                    </div>
                </div>
                <Link className='nolink' to={`/cat-profile-sell/${catId}`}>
                    <div style={styles.text}>
                        {language === 'en' ? cat?.breed_en : cat?.breed_rus}
                    </div>
                </Link>
            </div>
        </div >
    );
}

export default CatCardSellSmall