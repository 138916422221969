import { createContext, useState, useEffect, useContext } from "react";
import { loginUser, logoutApi } from "../services/api";

const AuthContext = createContext(null);

///// Auth Context Provider
export const AuthContextProvider = ({ children }) => {

    // console.log('authContext Provider evoked')

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userRoles, setUserRoles] = useState([]);
    const [currentUser, setCurrentUser] = useState(
        () => JSON.parse(localStorage.getItem("userKey")) || null)

    // useEffect(() => {
    //     const fetchAuthStatus = async () => {
    //         try {
    //             // function to check authentication status
    //             const resData = await checkAuthStatus();
    //             console.log('authContext.js useEffect resData = ', resData)
    //             if (resData) {
    //                 // console.log('isAuthenticated is true')
    //                 // setCurrentUser(JSON.parse(localStorage.getItem("userKey")));
    //                 // setIsAuthenticated(resData.isAuthenticated);
    //                 console.log('fetchAuthStatus resData.userInfo.roleIds = ', resData.userInfo.roleIds)
    //                 setUserRoles(resData.userInfo.roleIds)
    //             } else {
    //                 // console.log('isAuthenticated is FALSE')
    //                 localStorage.removeItem("userKey");
    //                 // setCurrentUser(null);
    //                 // setIsAuthenticated(false);
    //             }
    //         } catch (error) {
    //             console.error('Error during auth status check: ', error);
    //         }
    //     };
    //     fetchAuthStatus();
    // }, []);
    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem("userKey"));
        if (storedUser) {
            setCurrentUser(storedUser);
            setIsAuthenticated(true);
            setUserRoles(storedUser.roleIds);
        }
    }, []);

    //we need to update our localstorage every time currentUser changes
    // useEffect(() => {
    //     localStorage.setItem("userKey", JSON.stringify(currentUser))
    // }, [currentUser]);
    //it runs only when currentUser changes 
    // Update localStorage whenever currentUser changes
    useEffect(() => {
        if (currentUser) {
            localStorage.setItem("userKey", JSON.stringify(currentUser));
        } else {
            localStorage.removeItem("userKey");
        }
    }, [currentUser]);
    
    const login = async (inputs) => {
        try {
            const res = await loginUser(inputs) // it calls API.js to get some data
            // console.log('authContext | login | res:', res) //{success:true, data: { id: 55, email: 'onpet...}}
            setCurrentUser(res?.data);
            setIsAuthenticated(true);
            setUserRoles(res?.roleIds)
            return res; // <-- Return the response from loginUser
        } catch (error) {
            console.error('Error during login: ', error);
            throw error; // Propagate error up
        }
    };

    const logout = async () => {
        try {
            await logoutApi();
            setCurrentUser(null);
        } catch (error) {
            console.error("Error during logout: ", error);
            throw error; // Propagate error up
        }
    };


    return (
        <AuthContext.Provider value={{ currentUser, login, logout, isAuthenticated, userRoles }}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => useContext(AuthContext); // return current user object //custom hook?


//{ children } = App.js
//value={{ currentUser, login, logout }}> - inside {} we have props 