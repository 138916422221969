import React from 'react'
import Logo from "../../img/logo-nfc.png"
import "../Header/header.scss"
import { Link } from 'react-router-dom'
import './footer.scss'

import { useContext } from "react";
import { LanguageContext } from "../../context/langContext";

const Footer = () => {
  const { translations } = useContext(LanguageContext)

  return (
    <footer>
      <div className='footer__img' >
        <img src={Logo} alt="logo" />
      </div>
      {/* <span>Made by Nadia </span> */}
      <Link className='footer__link' to='./admin'>Admin</Link>
      <Link className='footer__link' to='./partners'>{translations.footer.areYou}</Link>
    </footer>
  )
}

export default Footer
