import { Link, useLocation } from "react-router-dom"
// import { useEffect } from 'react';
// import { useState } from 'react';
// import axios from 'axios';
import MainPhoto from "../../../src/img/catBritish.jpg";
import breed1 from "../../../src/img/CatBreedsPhoto/Abby.jpg";
import breed2 from "../../../src/img/CatBreedsPhoto/BritishShort.jpg";
import breed3 from "../../../src/img/CatBreedsPhoto/ScottishFold.jpg";
import breed4 from "../../../src/img/CatBreedsPhoto/SFL.jpg";
import breed5 from "../../../src/img/CatBreedsPhoto/Persian.jpg";
import breed9 from "../../../src/img/CatBreedsPhoto/Siamese.jpg";
import breed11 from "../../../src/img/CatBreedsPhoto/BritishLong.jpg";
import mainCoon from "../../../src/img/CatBreedsPhoto/MainCoon_AI.png";
import sph from "../../../src/img/CatBreedsPhoto/CanadianSphynx.jpg";
import ben from "../../../src/img/CatBreedsPhoto/Bengal.jpg";
import nev from "../../../src/img/CatBreedsPhoto/Nev_AI_transp.png";
import sib from "../../../src/img/CatBreedsPhoto/Siberian_AI_transp.png";
import bur from "../../../src/img/CatBreedsPhoto/Burma.jpg";

// import { useContext } from 'react'Ma
//============
import "./main.scss"
// import { useAuth } from "../../context/authContext";
import { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../context/langContext";
// import CatCardSellSmall from "../../components/CatCardSellSmall/CatCardSellSmall.jsx";
// import CatCard from "../../components/CatCard/CatCard.jsx";
import SellBlockSmall from "../../components/SellBlockSmall/SellBlockSmall.jsx";
// import { useAuth } from '../../context/authContext.js';

const Main = () => {
  const mainphoto1 = '/images/catBritish.jpg'
  const { translations } = useContext(LanguageContext)
  const [itemsToShow, setItemsToShow] = useState(1);

  //For Yandex.metric
  const location = useLocation();
  if (window.location.hostname !== 'localhost') {
    window.ym(95428596, 'hit', location.pathname);
  }
  const breedsData = {
    breeds: [
      {
        imgSrc: breed1,
        alt: "Abbysinian",
        caption: translations.allBreeds.aby
      },
      {
        imgSrc: breed11,
        alt: "Brittish Longhair",
        caption: translations.allBreeds.blh
      },
      {
        imgSrc: breed3,
        alt: "Scottish Fold Shorthair",
        caption: translations.allBreeds.sfs
      },
      {
        imgSrc: mainCoon,
        alt: "Main Coon",
        caption: translations.allBreeds.mco
      },
      {
        imgSrc: nev,
        alt: "Nev",
        caption: translations.allBreeds.nev
      },
      {
        imgSrc: sib,
        alt: "Siberian",
        caption: translations.allBreeds.sib
      },
      {
        imgSrc: ben,
        alt: "Bengal",
        caption: translations.allBreeds.ben
      },
      {
        imgSrc: breed2,
        alt: "British Shorthair",
        caption: translations.allBreeds.bri
      },
      {
        imgSrc: breed5,
        alt: "Persian",
        caption: translations.allBreeds.per
      },
      {
        imgSrc: breed4,
        alt: "Scottish Fold Longhair",
        caption: translations.allBreeds.sfl
      },
      {
        imgSrc: breed9,
        alt: "Siamese",
        caption: translations.allBreeds.sia
      },
      {
        imgSrc: sph,
        alt: "Canadian Spynx",
        caption: translations.allBreeds.sph
      },
      {
        imgSrc: bur,
        alt: "Burmese",
        caption: translations.allBreeds.bur
      },
    ]
  };

  const BreedsComponent = () => (
    <div className="main-breeds__breeds">
      {breedsData.breeds.slice(0, itemsToShow).map((breed, index) => (
        <div className="main-breeds__img-container" key={index}>
          <img className="main-breeds__img" src={breed.imgSrc} alt={breed.alt} />
          <div className="image-caption">{breed.caption}</div>
        </div>
      ))}
    </div>
  );


  // const [cats, setCats] = useState([]);
  useEffect(() => {
    const handleResize = () => {
      // console.log("innderWidth = ", window.innerWidth)
      //Since we have a media on 768 with grid 4 colomns, we need to show 8 or 12 items for beauty
      if (window.innerWidth >= 1200) {
        setItemsToShow(12);
      } else if (window.innerWidth >= 1024) {
        setItemsToShow(10);
      } else if (window.innerWidth >= 768) {
        setItemsToShow(8);
      } else {
        setItemsToShow(6);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial value based on current window width

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  // const { currentUser } = useContext(useAuth);
  // const auth = useAuth();

  // useEffect(() => {
  //   const fetchAllCats = async () => {
  //     try {
  //       const res = await axios.get("http://localhost:8800/api/cats") //we made a request to bring us info from front on this http address
  //       setCats(res.data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  //   fetchAllCats()
  // }, [])
  // const auth = useAuth();
  // const currentUser = auth.currentUser;

  return (
    <main className='main'>
      <section className="main-block">
        <div className="img-container">
          <img src={MainPhoto || mainphoto1} alt="a cat with a kittens"></img>
          <div className="text-overlay">
            <div className="main-text__header">
              {/* <div className="cattery-with-breeder__names"> */}
              <h2>{translations.main.header.h1}</h2>
              <div className="cattery-with-breeder__text">
                <p>{translations.main.description.d1}</p>
                <Link to="../market"><button className="primary-button-small">{translations.button.buy}</button></Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="main-breeds__wrap">
        <h2>{translations.main.header.h2}</h2>
        <BreedsComponent />
        {/* WILL BE UNCOMMENTED IN THE NEXT VERSION = need to make catbreeds page */}
        {/* <Link to="../catbreeds"><button className="button2">{translations.button.allbreeds}</button></Link> */}
      </section>
      <section className="main-sell__wrap">
        <h2>{translations.main.header.h3}</h2>
          <SellBlockSmall />
        <div>

        </div>
      </section>
    </main>
)
}


export default Main
