import React, { useState } from 'react'
import './TabView.scss'

function TabView({ title, tabs = [] }) {
    //was tabs = {}
    const [activeTabIndex, setAcriveTabIndex] = useState(0);

    const activateTab = (index) => {
        setAcriveTabIndex(index);
    }

    return (
        <div className='TabView'>
            {title && <h4 className='title'>{title}</h4>}
            <div className="body">
                {Object.keys(tabs).length === 0 ?
                    (<div>No Tabs</div>
                    ) : (
                        <div>
                            <div className='tabs'>
                                {tabs.map((tab, index) => (
                                    <div key={index} className={index === activeTabIndex ? "active-tab" : "tab"}
                                        onClick={() => activateTab(index)}>{tab.name}
                                    </div>
                                ))}
                            </div>
                            <div className="tab-content">{tabs[activeTabIndex].content}</div>
                        </div>
                    )}
            </div>
        </div>
    )
}

export default TabView