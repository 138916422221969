import React from 'react';
import './dialogModal.scss'
//dialogOpenMetric
export const DialogModal = (props) => {
    // console.log('props: ', props)
    return (
        <React.Fragment>
            {props.dialogOpen && (
                <dialog id={props.title} className="cat-tab__dialog" open={props.dialogOpen} onClose={props.onCloseDialog}>
                    <div className='cat_tab__dialog_div'>
                        <div className="title-and-close">
                            <h2>{props.title}</h2>
                            <p> {props.num}</p>
                            <form method="dialog">
                                <button className='profile-button'><span className='big'>X</span></button>
                            </form>
                        </div>
                        <figure className='dialog__img'>
                            {props.img && <img src={props.img} alt="Metric here" />}
                        </figure>
                        <form method="dialog">
                            <button className='profile-button'>Close</button>
                        </form>
                    </div>
                </dialog>
            )}
        </React.Fragment>
    )
}