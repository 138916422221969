import React, { useState, useEffect, useContext } from 'react'
import {useNavigate, useParams } from 'react-router-dom';

//MY MODULES//
import "./editcatprofile.scss"
import { useAuth } from '../../context/authContext.js';
import FormInput from '../../components/FormInput/FormInput';
import fetchCatColorForSelect from '../../services/fetchCatColorForSelect';
import { fetchBreeds, fetchUsers, putCat, putColor } from '../../services/api';
import { Clue } from '../../components/ui/Clue/Clue.jsx';
import { CatContext, CatContextProvider } from '../../context/CatContext.js';
import { LanguageContext } from '../../context/langContext.js';

const EditCatProfile = () => {
    // const urlParts = window.location.pathname.split('/');
    // console.log('urlParts = ', urlParts)
    // const catId = urlParts[urlParts.length - 2];
    const { id: catId } = useParams(); // Grabs catId from the URL
    // console.log("EditCatProfile | catId", catId)
    return (
        <CatContextProvider catId={catId}>
            <UpdateChild catId={catId} />
        </CatContextProvider>
    );
}

const UpdateChild = ({catId}) => {
    // console.log('UpdateChild | catId: ', catId)
    const { translations } = useContext(LanguageContext)
    const { data: cat } = useContext(CatContext);
    // console.log('EditCatProfile | catContext | cat = ', cat)
    const auth = useAuth() //auth.currentUser from /context/authContext.js
    // const location = useLocation();
    const navigate = useNavigate()
    // const catId = location.pathname.split("/")[2] //http://localhost:3001/post/1 - we take post number from URL address in browser

    const [breeds, setBreedsOption] = useState([]);
    const [breeders, setBreeders] = useState([]);
    // const [colorValues, setColorValues] = useState({
    //     main_ems: null,
    //     delute_ems: null,
    //     gold_ems: null,
    //     point_ems: null,
    //     tabby_ems: null,
    //     white_ems: null,
    //     eye_ems: null,
    //     colorFullEms: null
    // });
    // const [newColor, setNewColor] = useState({}) // selected color which will be send to server
    // const [catColors, setCatColors] = useState({});
    const [catValues, setCatValues] = useState({
        cat_name_cattery_prefix: cat?.cat_name_cattery_prefix || "",
        cat_name: cat?.cat_name || "",
        cat_name_suffix: cat?.cat_name_suffix || "",
        sex: cat?.sex || "",
        cat_birthdate: cat?.cat_birthdate_input | "",
        breed_id: cat?.breed_id || "",
        currentBreedSelect: { value: cat?.breed_id || "", label: cat?.breed_name || "Select Breed" },
        description: cat?.description ?? "",  // Use nullish coalescing to handle null values
        breeder_id: cat?.breeder_id || "",
        breeder_not_user: cat?.breeder_not_user || "",
        breederSelect: { value: cat?.breeder_id || "", label: cat?.breeder_name || "Select Breeder" },
        chip: "",
        is_my_cat: cat?.is_my_cat || 0,
        colors: {
            main_ems: { value: "", label: "Select Main Color" },  // Initialize with a default object
            delute_ems: { value: "", label: "Select Delute Color" },
            gold_ems: { value: "", label: "Select Gold Color" },
            point_ems: { value: "", label: "Select Point Color" },
            tabby_ems: { value: "", label: "Select Tabby Color" },
            white_ems: { value: "", label: "Select White Color" },
            eye_ems: { value: "", label: "Select Eye Color" }
        }
    });
    const [colorOptions, setColorOptions] = useState({})
    const [isWhite, setIsWhite] = useState(true) // selected color which will be send to server
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(cat?.is_my_cat || false);

    // console.log('catValues = ', catValues)
    //fetch Cat info from DB and store in state 'cat'
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [resBreeds, resColors, resUsers] = await Promise.all([
                    // fetchOneCat(catId),
                    fetchBreeds(),
                    fetchCatColorForSelect(),
                    fetchUsers()
                ])

                // setCatValues((prev) => ({ ...prev, ...resData, cat_birthdate: formatDateFromDB(resData.cat_birthdate) }))

                let breed = [];
                for (let i = 0; i < resBreeds.length; i++) {
                    let obj = {};
                    let { id: value, breed: label, ems } = resBreeds[i]
                    label = `${ems} - ${label}`;
                    obj.value = value;
                    obj.label = label;
                    breed.push(obj);
                }
                setBreedsOption(() => (breed))
                // console.log('resBreeds = ', resBreeds)
                const currentCatBreedRow = resBreeds.find(breed => breed.id === cat?.breed_id);
                // console.log('currentCatBreedRow = ', currentCatBreedRow)
                const currentCatBreed = { "value": currentCatBreedRow?.id, "label": currentCatBreedRow?.breed }
                // console.log('currentCatBreed = ', currentCatBreed)


                //Get an objject with all colors for using it in Select inputs.
                // const colors = await fetchCatColorForSelect();
                // console.log('colors from AddParent', colors);

                //  Set color options //
                setColorOptions(resColors);
                // console.log('resColors = ', resColors);
                // Set initial color values
                // setColorValues({
                //     main_ems: resColors.arrayMainColor.find(color => color.value === cat?.cat_color_main_ems),
                //     gold_ems: resColors.arrayGoldColor.find(color => color.value === cat?.cat_color_gold_ems),
                //     white_ems: resColors.arrayWhiteColor.find(color => color.value === resData.cat_color_white_ems),
                //     tabby_ems: resColors.arrayTabbyColor.find(color => color.value === resData.cat_color_tabby_ems),
                //     point_ems: resColors.arrayPointColor.find(color => color.value === resData.cat_color_point_ems),
                //     eye_ems: resColors.arrayEyeColor.find(color => color.value === resData.cat_color_eye_ems),
                // });
                // console.log('colorValues = ', colorValues)
                // const currentCatColorRow = resColors.arrayMainColor.find(color => color.value === cat.cat_color_main_ems);
                // console.log('currenCatColorRow = ', currentCatColorRow);
                // setColorValues((prev) => ({ ...prev, main_ems: currentCatBreedRow }))
                // setCatColors((prev) => ({ ...prev, arrayMainColor: currentCatBreedRow }))
                // const resUsers = await fetchUsers();
                const arrayBreedersSelect = resUsers.map((item) => ({
                    value: item.id,
                    label: `${item.first_name} ${item.last_name}`
                }))
                const emptyObject = { value: '', label: '... No breeder ...' } //value can NOT be NULL
                arrayBreedersSelect.push(emptyObject);
                setBreeders(() => (arrayBreedersSelect))
                // console.log('arrayBreedersSelect', arrayBreedersSelect);
                const currentBreederSelect = arrayBreedersSelect.find(breeder => breeder.value === cat?.breeder_id)
                if (cat) {
                    setCatValues(() => ({
                        ...cat,
                        description: cat?.description || "",
                        cat_birthdate: cat?.cat_birthdate_input || "",
                        breeder_id: currentBreederSelect?.value,
                        currentBreedSelect: currentCatBreed || "",
                        breederSelect: currentBreederSelect,
                        colors: {
                            // ...prevState.colors,
                            main_ems: resColors.arrayMainColor.find(color => color.value === cat?.cat_color_main_ems || ""),
                            gold_ems: resColors.arrayGoldColor.find(color => color.value === cat?.cat_color_gold_silver_ems || ""),
                            white_ems: resColors.arrayWhiteColor.find(color => color.value === cat?.cat_color_white_ems || ""),
                            tabby_ems: resColors.arrayTabbyColor.find(color => color.value === cat?.cat_color_tabby_ems || ""),
                            point_ems: resColors.arrayPointColor.find(color => color.value === cat?.cat_color_point_ems || ""),
                            eye_ems: resColors.arrayEyeColor.find(color => color.value === cat?.eye_color_ems || ""),
                        }
                    }))
                    setIsCheckboxChecked(cat?.is_my_cat)
                }

                // setCatValues((prev) => ({
                //     ...prev,
                //     breederSelect: currentBreederSelect,
                //     breeder_id: currentBreederSelect?.value
                // }))
                // one cat's color

                // console.log("Current catValues state:", catValues);
            } catch (err) { console.log(err) }
        }
        fetchData();
    }, [catId, cat])

    const onChangeBreed = (selectedOption) => {
        // console.log('selectedOption = ', selectedOption)
        setCatValues((prev) => ({ ...prev, currentBreedSelect: selectedOption, breed_id: selectedOption.value }))
    };

    const onChangeSex = (e) => {
        setCatValues((prev) => ({ ...prev, [e.target.name]: e.target.value }))
    };

    // const onChangeMainColor = (selectedOption) => {
    //     setColorValues((prev) => ({ ...prev, main_ems: selectedOption.value }))
    //     if (selectedOption.value !== "w") {
    //         // console.log('selectedOption.value', selectedOption.value);
    //         setIsWhite(false);
    //     } else { setIsWhite(true) }
    //     // console.log("color selectedOption.value", selectedOption.value)
    // };
    const onChangeMainColor = (selectedOption) => {
        setCatValues(prev => ({
            ...prev,
            colors: { ...prev.colors, main_ems: selectedOption }
        }));
        setIsWhite(selectedOption.value === "w");
    };

    const onChangeColor = (colorName, selectedOption) => {
        setCatValues(prev => ({
            ...prev,
            colors: { ...prev.colors, [colorName]: selectedOption }
        }));
    };

    // const onChangeGoldColor = (selectedOption) => {
    //     // setNewColor((prev) => ({ ...prev, gold_ems: selectedOption.value }))
    //     // console.log("color selectedOption.value", selectedOption.value)
    // };
    // const onChangeWhite = (selectedOption) => {
    //     // setNewColor((prev) => ({ ...prev, white_ems: selectedOption.value }))
    //     // console.log("color selectedOption.value", selectedOption.value)
    // };
    // const onChangeTabby = (selectedOption) => {
    //     // setNewColor((prev) => ({ ...prev, tabby_ems: selectedOption.value }))
    //     // console.log("color selectedOption.value", selectedOption.value)
    // };
    // const onChangePoint = (selectedOption) => {
    //     // setNewColor((prev) => ({ ...prev, point_ems: selectedOption.value }))
    //     // console.log("color selectedOption.value", selectedOption.value)
    // };
    // const onChangeEye = (selectedOption) => {
    //     // setNewColor((prev) => ({ ...prev, eye_ems: selectedOption.value }))
    //     // console.log("color selectedOption.value", selectedOption.value)
    // };

    const onChange = (e) => {
        setCatValues((prev) => ({ ...prev, [e.target.name]: e.target.value, users_id: auth.currentUser.id }))
    };

    const onChangeBreeders = (selectedBreeder) => {
        // console.log('selectedBreeder', selectedBreeder)
        setCatValues((prev) => ({ ...prev, breederSelect: selectedBreeder, breeder_id: selectedBreeder.value }))
    }

    const inputs = [
        {
            id: "cat_name_cattery_prefix",
            name: "cat_name_cattery_prefix",
            type: "text",
            placeholder: "Cattery prefix",
            errorMessage: "Use 3-100 letters or numbers",
            label: "Cattery prefix of a cat name",
            pattern: "^[А-Яа-яA-Za-z0-9 ']{3,100}$",
            required: false,
            onChange: onChange,
            value: catValues?.cat_name_cattery_prefix || "",
            maxLength: 100 // limit to 100 characters
        },
        {
            id: "cat_name",
            name: "cat_name",
            type: "text",
            placeholder: "Cat name",
            label: "Cat name (without cattery prefix)*",
            errorMessage: "Use 3-100 letters or numbers",
            pattern: "^[А-Яа-яA-Za-z0-9 ']{3,100}$",
            required: true,
            onChange: onChange,
            value: catValues?.cat_name || "",
            maxLength: 100
        },
        {
            id: "cat_name_suffix",
            name: "cat_name_suffix",
            type: "text",
            label: "Cattery suffix",
            placeholder: "Cattery suffix",
            errorMessage: "Use 3-100 letters or numbers",
            pattern: "^[А-Яа-яA-Za-z0-9 ']{3,100}$",
            required: false,
            onChange: onChange,
            value: catValues?.cat_name_suffix || "",
            maxLength: 100 // limit to 100 characters
        },
        {
            id: "currentBreedSelect",
            name: "currentBreedSelect",
            type: "select",
            label: "Breed*",
            placeholder: "Breed",
            options: breeds,
            onChange: onChangeBreed,
            required: true,
            value: catValues?.currentBreedSelect || "",

        },
        {
            id: "sex",
            name: "sex",
            type: "radio",
            label: "Gender*",
            className: 'radio-button__input',
            onChange: onChangeSex,
            options: ['male', 'female'],
            value: catValues?.sex || "",
            required: false,
        },
        //     <div className="cat-edit__form-input">
        //     <label>Birthdate<input type="date" name='cat_birthdate' onChange={onChange} /></label>
        //     </div>
        {
            id: "cat_birthdate",
            name: "cat_birthdate",
            type: "date",
            label: "Cat birthday*",
            onChange: onChange,
            required: true,
            value: catValues?.cat_birthdate || "",
        },
        //     <div >
        //     <textarea className='cat-edit__textarea' rows={3} cols={10} 
        //     placeholder='Some cat description' onChange={onChange} name='description'></textarea>
        //     </div>
        {
            id: "description",
            name: "description",
            type: "textarea",
            label: "Cat description",
            placeholder: "Cat description",
            onChange: onChange,
            maxLength: 100,
            required: false,
            value: catValues?.description || "",
        },
        //     <div className="form-input-select">
        //     <label>Breeder</label>
        //     <Select options={breeder} onChange={onChangeBreeders} />
        //     </div>
        {
            id: "breederSelect",
            name: "breederSelect",
            type: "select",
            label: "Breeder",
            placeholder: "Breeder",
            options: breeders,
            onChange: onChangeBreeders,
            required: false,
            value: catValues?.breederSelect || "",
        },
        {
            id: "breeder_not_user",
            name: "breeder_not_user",
            type: "text",
            label: "Breeder. If you did't find a registered breeder above, you can write a new one",
            placeholder: "Breeder",
            errorMessage: "Use 3-100 letters or numbers",
            pattern: "^[А-Яа-яA-Za-z0-9 .]{3,100}$",
            required: false,
            onChange: onChange,
            value: catValues?.breeder_not_user || "",
            maxLength: 100, // limit to 100 characters
        },
        {
            id: "chip",
            name: "chip",
            type: "text",
            label: "Chip",
            placeholder: "Chip",
            onChange: onChange,
            errorMessage: "Use 3-100 letters or numbers",
            pattern: "^[А-Яа-яA-Za-z0-9 ]{3,100}$",
            required: false,
            value: catValues?.chip || "",
            maxLength: 100, // limit to 100 characters
        }
    ]

    const inputsMainColor = [
        {
            id: "main_ems",
            name: "main_ems",
            type: "select",
            label: "Main Color*",
            options: colorOptions?.arrayMainColor,
            onChange: onChangeMainColor,
            required: true,
            value: catValues?.colors?.main_ems || '' // Set the value to the current main color
        }
    ]
    const inputsColor = [
        {
            id: "gold",
            name: "gold",
            type: "select",
            label: "Gold or Silver",
            options: colorOptions?.arrayGoldColor,
            onChange: selectedOption => onChangeColor('gold_ems', selectedOption),
            required: false,
            value: catValues?.colors?.gold_ems || "",
        },
        {
            id: "white",
            name: "white",
            type: "select",
            label: "White spots",
            options: colorOptions?.arrayWhiteColor,
            onChange: selectedOption => onChangeColor('white_ems', selectedOption),
            required: false,
            value: catValues?.colors?.white_ems || ""
        },
        {
            id: "tabby",
            name: "tabby",
            type: "select",
            label: "Tabby",
            options: colorOptions?.arrayTabbyColor,
            onChange: selectedOption => onChangeColor('tabby_ems', selectedOption),
            required: false,
            value: catValues?.colors.tabby_ems || ""
        },
        {
            id: "point",
            name: "point",
            type: "select",
            label: "Point",
            options: colorOptions?.arrayPointColor,
            onChange: selectedOption => onChangeColor('point_ems', selectedOption),
            required: false,
            value: catValues?.colors.point_ems || "",
        },
        {
            id: "eye",
            name: "eye",
            type: "select",
            label: "Eye color",
            options: colorOptions?.arrayEyeColor,
            onChange: selectedOption => onChangeColor('eye_ems', selectedOption),
            required: false,
            value: catValues?.colors.eye_ems || "",
        },
    ]

    const handleSubmit = async (e) => {
        e.preventDefault();
        const colorsForSending = {
            eye_ems: catValues.colors?.eye_ems?.value,
            gold_ems: catValues.colors?.gold_ems?.value,
            main_ems: catValues.colors?.main_ems?.value,
            point_ems: catValues.colors?.point_ems?.value,
            tabby_ems: catValues.colors?.tabby_ems?.value,
            white_ems: catValues.colors?.white_ems?.value
        }
        try {
            // console.log('EditCatProfile.js | catValues before submit = ', catValues)
            // console.log('EditCatProfile.js | colorsForSending before submit = ', colorsForSending)
            // await putCat(catValues);
            // Use Promise.all to send multiple requests concurrently
            await Promise.all([
                putColor(catValues, colorsForSending), // This sends a PUT request to update the color
                putCat(catValues), // This function updates the cat data
            ]);
            navigate(`/cats/${catValues.id}`);
        } catch (error) {
            if (error.response) {
                alert(`Server responded with an error: ${error.response.status}`);
            } else if (error.request) {
                alert("No response received from the server. Please try again.");
            } else {
                alert("An error occurred. Please try again later.");
            }
        }
    }


    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     // console.log('catValues in handleSubmit = ', catValues);
    //     // console.log('breeders.id = ', breeders.id);
    //     // console.log("color before sending", newColor)
    //     try {
    //         // Use Promise.all to send multiple requests concurrently
    //         await Promise.all([
    //             // putColor(catValues, newColor), // This sends a PUT request to update the color
    //             putCat(catValues), // This function updates the cat data
    //         ]);

    //         // Both requests completed successfully
    //         navigate(`/cats/${catValues.id}`);
    //     } catch (error) {
    //         // Provide specific feedback to the user based on the error
    //         if (error.response) {
    //             // The request was made and the server responded with a status code
    //             // that falls out of the range of 2xx
    //             alert(`Server responded with an error: ${error.response.status}`);
    //         } else if (error.request) {
    //             // The request was made but no response was received
    //             alert("No response received from the server. Please try again.");
    //         } else {
    //             // Something happened in setting up the request that triggered an Error
    //             alert("An error occurred. Please try again later.");
    //         }

    //         // Optionally: implement retry logic or other error handling here        }
    //     }
    // }

    const handleCheckboxChange = (event) => {
        setIsCheckboxChecked(event.target.checked);
        // console.log('handleCheckboxChange event.target.checked =', event.target.checked)
        setCatValues((prev) => ({ ...prev, is_my_cat: event.target.checked ? 1 : 0 }))
    }

    const cancelEdit = () => {
        // console.log('cancelEdit | catId: ', catId)
        navigate(`/cats/${catId}`)
    }
    // console.log('EditCatProfile | catcontext:cat = ', cat)

    return (
        <div className="cat-edit-container">
            <div className="cat-edit">
                <form
                    onSubmit={handleSubmit}>
                    <h2>Edit Cat Profile - ID{catValues.id}</h2>
                    {inputs.map((input) => (
                        // let valueSelect = {};
                        // if (input.type === 'select') {
                        //     if (input.name === "breed") {
                        //         // Find the option that matches the current value for select inputs
                        //         // valueSelect = input.options.find(option => option.value === catValues.breed_id) || '';
                        //         // console.log('catValues[input.name] =', catValues[input.name])
                        //         // console.log('EditCatProfile valueSelect =', valueSelect)
                        //     }
                        //     if (input.name === "breeder") {
                        //         // console.log('input.name = ', input.name)
                        //         // console.log('input.options = ', input.options)
                        //         // valueSelect = input.options.find(option => option.value === catValues.breeder_id) || '';

                        //     }
                        // }
                        // return (
                        <FormInput
                            key={input.id}
                            {...input}
                        />)
                    )}
                    <div className='cat-color'>
                        {translations?.edit_cat_profile?.current_cat_color} <b> {cat?.colorFullEms}</b>
                    </div>
                    <h3>You can edit color here</h3>
                    <div className='cat-color'>
                        {catValues?.colors?.main_ems ? (
                            <div className='edit-cat-profile__color'>
                                <p className='edit-cat-profile__text'>A new cat color: </p>
                                <strong>
                                    <div>
                                        {catValues.colors.main_ems?.value && <span>{catValues.colors.main_ems.value}</span>}
                                        {catValues.colors.gold_ems?.value && <span>{catValues.colors.gold_ems.value}</span>}
                                        {catValues.colors.white_ems?.value && <span>{catValues.colors.white_ems.value}</span>}
                                        {catValues.colors.tabby_ems?.value && <span>{catValues.colors.tabby_ems.value}</span>}
                                        {catValues.colors.point_ems?.value && <span>{catValues.colors.point_ems.value}</span>}
                                        {catValues.colors.delute_ems?.value && <span>{catValues.colors.delute_ems.value}</span>}
                                        {catValues.colors.eye_ems?.value && <span>{catValues.colors.eye_ems.value}</span>}
                                    </div>
                                    {/* <div>
                                        {catValues?.colors?.main_ems && <span>{catValues.colors.main_ems.value !== undefined ? catValues.colors.main_ems.value : ""} </span>}
                                        {catValues?.colors?.gold_ems && <span>{catValues.colors.gold_ems.value !== undefined ? catValues.colors.gold_ems.value : ""} </span>}
                                        {catValues?.colors?.white_ems && <span>{catValues.colors.white_ems.value !== undefined ? catValues.colors.white_ems.value : ""} </span>}
                                        {catValues?.colors?.tabby_ems && <span>{catValues?.colors?.tabby_ems?.value} </span>}
                                        {catValues?.colors?.point_ems && <span>{catValues?.colors?.point_ems?.value} </span>}
                                        {catValues?.colors?.delute_ems && <span>{catValues?.colors?.delute_ems?.value} </span>}
                                        {catValues?.colors?.eye_ems && <span>{catValues?.colors?.eye_ems?.value}</span>}
                                    </div> */}
                                </strong>
                            </div>
                        ) : (
                            <>
                                <p className='edit-cat-profile__text'>A new cat color: you will see here color changed</p>
                            </>
                        )}
                    </div>
                    {
                        inputsMainColor.map((input) => (
                            <FormInput
                                key={input.id}
                                {...input}
                            // id={input.id}
                            // name={input.name}
                            // value={input.value}
                            // placeholder={input.placeholder}
                            // required={input.required}
                            // options={input.options}
                            // onChange={input.onChange}
                            // onChangeSelect={input.onChange}
                            // options={input.options}
                            />
                        ))
                    }
                    {
                        inputsColor.map((input) => (!isWhite &&
                            <FormInput
                                key={input.id}
                                {...input}
                                id={input.id}
                                name={input.name}
                                value={input.value}
                                options={input.options}
                                onChange={input.onChange}
                            // onChangeSelect={input.onChange}
                            />
                        ))
                    }
                    <input
                        type="checkbox"
                        onChange={handleCheckboxChange}
                        // defaultChecked={isCheckboxChecked}  // Controlled by the state
                        checked={isCheckboxChecked}  // Controlled by the state
                    /> <label>It's my cat - check if you are the owner</label>

                    <Clue text={
                        "When adding a new cat to the CatClub website, avoid selecting 'it is my cat' unless the cat is personally owned by you. For instance,if you are including multiple cats as ancestors in your cat's pedigree, this option should not be marked."
                    } />

                    <button className='joinButton'>Send</button>
                    <button type='button' className='button-light' onClick={cancelEdit}>Cancel</button>
                </form>
            </div>
        </div>
    )
}

export default EditCatProfile
