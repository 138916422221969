
import React, { useState, useEffect } from 'react'
// import { Link, useLocation, useNavigate } from 'react-router-dom';

import './addCatSearch.scss'
import CatMiniCard from '../../components/CatMiniCard';
import { fetchCatsWithColor10Free, postCatChangeOwner, updateOwnerAtCat } from '../../services/api';
import { useAuth } from '../../context/authContext';
import { useLocation, useNavigate } from 'react-router-dom';

// We can ad all cats where we are NOT an online owner (cat.users_id != auth.currentUser.id) because we already have them on the page
// and we can ad cats which is not by anybody

const AddCatSearch = () => {

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    // const [catsSelect, setCatsSelect] = useState({
    //     id: "",
    //     cat_name: "",
    //     sex: "male",
    //     cat_birthdate: "",
    //     breed_id: "",
    //     users_id: ""
    // });
    const auth = useAuth();
    const userId = auth.currentUser.id;
    console.log('AddCatSearch | userId: ', userId);
    const location = useLocation()
    let queryParentFromURL = useQuery();

    const parentGender = queryParentFromURL.get("p");  // This will get the value of the q parameter, so "father" in your example

    let catId = location.pathname.split("/")[2] //http://localhost:3001/post/1 - we take post number from URL address in browser
    catId = parseInt(catId, 10)

    const [allCats, setAllCats] = useState([])
    const [selectedOption, setSelectedOption] = useState([]);
    const [query, setQuery] = useState("");

    useEffect(() => {
        const fetchAllCats = async () => {
            try {
                const resData = await fetchCatsWithColor10Free(query, parentGender)
                // setCatsSelect('resData', resData);
                // const onlyMyCats = res.data.filter((cat) => { return cat.users_id !== auth.currentUser.id })
                // const allCats = res.data;
                // console.log(' AddParentSearch resData = ', resData)
                const onlyMyCats = resData.filter((cat) => { return cat.users_id !== auth.currentUser.id })
                setAllCats(onlyMyCats);
            } catch (error) { console.log(error) }
        }
        if (query.length === 0 || query.length > 2) fetchAllCats();
    }, [auth.currentUser, query, parentGender])

    const navigate = useNavigate()

    // const handleAddMother = async e => {
    //     e.preventDefault();
    //     const motherId = selectedOption;
    //     // console.log("motherId", motherId);
    //     try {
    //         const response = await postMother(motherId, catId)
    //         console.log('response.data', response.data);
    //         // navigate(`/cats/${catId}`)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // const handleAddFather = async e => {
    //     e.preventDefault();
    //     const fatherId = selectedOption;
    //     console.log('catId', catId)
    //     console.log("fatherId", fatherId);
    //     try {
    //         const response = await postFather(fatherId, catId)
    //         console.log(response.data);
    //         navigate(`/cats/${catId}`)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // const handleFilterSelect = (filter) => {
    //     setSelectedFilter(filter);
    // }

    // const keys = ['cat_name', 'cat_name_cattery_prefix', 'cat_name_suffix']
    // const search = (data) => {
    //     return data.filter((item) =>
    //         keys.some((key) => item[key]?.toLowerCase().includes(query))
    //     );
    // }


    const onClickCat = (e) => {
        setSelectedOption(e)
        console.log('selectedOption e =', e) //[catId, catUser_id]
    }

    const handleSubmit = async e => {
        e.preventDefault();
        // const newCatId = selectedOption.map(option => option.value);
        const newCatId = selectedOption.catId;
        console.log("selectedOption [catId, catUser_id]", selectedOption);

        // let filteredValue = .filter(element => element.id === newCatId);
        console.log("newCatId = ", newCatId);
        // setSelectedValues(filteredValue);
        // setSelectedValues(filteredValue);
        // console.log("selectedValues", selectedValues);

        try {
            const responses = await Promise.all([
                updateOwnerAtCat(newCatId), //write ony users_id (a new one) in cat table //updateOwnerAtCat
                postCatChangeOwner(selectedOption) // {catId: 1, userId: 6} //we can't get userInfo from req, because it's not a current User (we get userId from cat data)
            ]);
            const [response1, response2] = responses;
            console.log(response1.data);
            console.log(response2.data);
            navigate("/my")
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className="searchCat__container">
            <div className="addCatSearch">
                <form>
                    {parentGender ? (
                        <>
                            <h1>Search a parent for ID {catId}</h1>
                            <p className='searchCat__p'>Check if a cat is already exist in all data base.</p>
                            <p>Just start typing a cat's name OR cat's prefix OR cat's suffix.

                            </p>
                        </>
                    ) : (
                        <>
                            <h1>Search a cat</h1>
                            <p>Check if a cat is already exist in all data base.</p>
                            <p className='searchCat__p'>You can see only cat's which is not on your page and is not offline owned by anybody.</p>
                            <p>Just start typing a cat's name OR cat's prefix OR cat's suffix.</p>
                        </>
                    )
                    }
                    <div className="search">
                        <input type="text" placeholder='Search...' className="search__input" onChange={(e) => setQuery(e.target.value.toLowerCase())} />
                    </div>
                    <p className='searchCat__p'>You see here only a small part of records. Start typing to see more results.</p>
                    <p>If you see 2 similar cats and one is registered - choose registered cat.</p>
                    <CatMiniCard data={allCats} userId={userId} onClickCat={onClickCat} />
                    <button className="joinButton" onClick={handleSubmit}>Add the cat to my page * </button>

                    {/* <CatMiniCard data={search(allCats)} onClickCat={onClickCat} /> */}
                    {/* {parentGender === 'male' ? (
                        <button className="joinButton" onClick={handleAddFather}>Add Father </button>
                    ) : parentGender === 'female' ? (
                        <button className="joinButton" onClick={handleAddMother}>Add Mother </button>
                    ) : (
                        <button className="joinButton" onClick={handleSubmit}>Add the cat to my page * </button>
                    )
                    }
                    {parentGender ? (
                        <div className="searchCat__button">
                            <Link to={`/cats/${catId}`}><button className="button-light">Back to cat page</button></Link>
                            <Link to={`/addparent`}><button className="button-light-main">Add a cat with color</button></Link>
                        </div>
                    ) : (
                        <></>
                    )
                    } */}
                </form>
            </div >
        </div>
    )
}


export default AddCatSearch
