import React, { useContext, useEffect, useState } from 'react'
import "./catprofile.scss";
import { useLocation, useNavigate } from 'react-router-dom';
// import formatDate from '../../utils/formatDate';
import { useAuth } from '../../context/authContext';
import CatProfileLow from './CatProfileLow/CatProfileLow.jsx';
import {
    deleteCat,
    fetchApplications,
    fetchDocs,
    // fetchOneCatAndColor,
    // fetchOneMetric,
    // fetchOnePedigree,
    fetchPrevOwnerFromCatChangeOwner,
    postCatChangeOwnerFromPrev,
    putUserFromPrev
} from '../../services/api';
// import Parents from './Parents';
import CatProfileUp from '../CatProfile/CatProfileUp/CatProfileUp.jsx';
import { CatContext, CatContextProvider } from '../../context/CatContext.js';
import { LanguageContext } from '../../context/langContext.js';

// export const CatProfileContext = createContext();

const CatProfile = () => {
    // const urlParts = window.location.pathname.split('/');
    // console.log('urlParts = ', urlParts);
    // const catId = urlParts[urlParts.length - 1];

    // Only this aproach (location) let catProfile reload when click parant's catcard on CatProfileLow //
    const location = useLocation();
    const urlParts = location.pathname.split('/');
    const catId = urlParts[urlParts.length - 1];
    // debugger; // Execution will pause here, and you can inspect `urlParts` and `catId`
    // console.log('CatProfile | catId', catId)

    // useEffect(() => {
    //     console.log(' CatProfile useEffect| catId', catId);
    //     // Any other logic you need when catId changes
    // }, [catId]);
    return (
        <CatContextProvider catId={catId} key={catId}>
            <CatProfileChild />
        </CatContextProvider>
    );
}

const CatProfileChild = () => {
    const { data, loading, error } = useContext(CatContext);
    // console.log('CatProfile | data', data);
    const cat = data;
    const catId = data?.id;
    const { translations } = useContext(LanguageContext)

    const navigate = useNavigate();
    // const location = useLocation();
    const auth = useAuth();
    const currentUserId = auth.currentUser.id;
    // const catId = location.pathname.split("/")[2] //http://localhost:3001/post/1 - we take post number from URL address in browser
    // const catId = cat?.id;
    // console.log('catProfileChild | cat?.id = ', cat?.id)
    const [error403, setError403] = useState('')
    const [state, setState] = useState({
        cat: {},
        metric: {},
        pedigree: {},
        applicationStatus: {},
        prevOwner: [],
    });

    useEffect(() => {
        if (!catId || !currentUserId) return; // Exit early if catId or currentUserId is not available

        const fetchCatRelatedData = async () => {
            // console.log('fetchCatRelatedData | cat: ', cat)

            try {
                const docs = await fetchDocs(catId, currentUserId) //pedigree and metrics in one object
                // console.log('fetchCatRelatedData | docs: ', docs)
                if (docs?.status === 403) {
                    console.log('docs?.data.message: ', docs?.data.message)
                    setError403(docs?.data.message);
                    return
                }
                const resDataApplication = await fetchApplications(catId); // data comes clean as an object with data (not the whole response)
                // console.log('fetchCatRelatedData | resDataApplication: ', resDataApplication) //expected res: { status: 'solve', id: 44}

                //fetch previous owner (the last one!, because we have date in created_at entity) from DB Table cat_change_owner
                const resDataPrevOwner = await fetchPrevOwnerFromCatChangeOwner(catId); //SELECT prev_owner_id FROM cat_change_owner 
                const updatedState = {
                    cat: cat || {},
                    metric: docs.metric,
                    pedigree: docs.pedigree,
                    applicationStatus: resDataApplication || {},
                    prevOwner: resDataPrevOwner || [], //[{prev_owner_id}] - we are sending to this
                    // birthdate: formatDate(cat.cat_birthdate),
                };

                // if (resDataApplication) updatedState.applicationStatus = resDataApplication;

                setState(prevState => ({ ...prevState, ...updatedState }));

            } catch (error) { console.log(error) }
        }
        fetchCatRelatedData();
    }, [catId, currentUserId])

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    const handleDelete = async () => {
        console.log('delete clicked')
        try {
            await deleteCat(catId)
            navigate('/my')
            // window.location.reload() //to force the document to be fetced from the web server again.
            // Without it, when we delete a cat - it deletes in DB, but still in frontend.In future it better to do it using REDUX or some other managment tools
        } catch (ex) {
            // Expected (404: not found, 400: bad request) - CLIENT ERRORS
            // - Display a specific error message
            if (ex.response && ex.response.status === 404)
                alert('Expected error occured'); // we don't need to log (console.log) errors which come from client
        }
    }
    // Moving cat from my page to prevOwner page
    const handleMove = async () => {
        try {
            const dataToSend = { catId: catId, prevOwner: state.prevOwner[0]?.prev_owner_id }
            // console.log('dataToSendForPut', dataToSend)
            const [response1, response2] = await Promise.all([
                //insert in CAT table a prevOwner 
                putUserFromPrev(dataToSend),
                postCatChangeOwnerFromPrev(dataToSend) //transaction and prevOwner (which was fetched before) in a DB Table cat_change_owner
            ]);

            console.log(response1.data, response2.data);
            navigate('/my')
        } catch (error) {
            console.error('Failed to move cat:', error);
        }
    }

    const catProfileValue = {
        ...state,
        error403,
        handleDelete,
        handleMove,
    }
    // console.log('CatProfile.jsx | catProfileValue = ', catProfileValue)

    // if (loading) return <p>Loading...</p>;
    // if (error) return <p>Error: {error.message}</p>;
    // if (!catId) return <p>No cat data available yet. Please wait...</p>; // Return early if catId is missing

    return (
        // <CatProfileContext.Provider value={catProfileValue}>
        <div className="cat-profile__container" >
            <h2 className='cat-profile__header'>{error403 ? translations.cat_profile.cat_profile_public : translations.cat_profile.cat_profile} </h2>
            <CatProfileUp catProfileValue={catProfileValue} />
            <CatProfileLow catProfileValue={catProfileValue} />
        </div>
        // </CatProfileContext.Provider>
    )
}

export default CatProfile

//key={catId} urge components rerender