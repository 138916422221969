import React, { useCallback, useEffect, useReducer } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FormInput from '../../components/FormInput/FormInput';
import './editCreateForm.scss';
import { useAuth } from '../../context/authContext';
import { formatValuesForSelect } from '../../utils/formatValuesForSelect';
import { UploadFile } from '../../components/UploadFile/UploadFile';
import { HandleUpload } from '../../components/UploadFile/HandleUpload';
import {
    fetchCatSys,
    fetchOnePedigree,
    fetchOneMetric,
    postPedigree,
    postMetric,
    putPedigree,
    putMetric,
    // putPedigreeURL,
    // putMetricURL
} from '../../services/api';
import { INITIAL_STATE, formReducer } from './formReducer';

const EditCreateForm = () => {
    const location = useLocation();
    const { entityType, isEdit } = location.state || { entityType: 'no', isEdit: false }; // Default values if state is not passed
    // console.log('location: ', location)
    // console.log(`EditCreateForm | entityType: ${entityType}, isEdit: ${isEdit}`)
    const [state, dispatch] = useReducer(formReducer, INITIAL_STATE);

    const navigate = useNavigate();
    const auth = useAuth();
    const currentUserId = auth.currentUser.id;
    const catId = location.pathname.split("/")[2] //http://localhost:3001/post/1 - we take a number from URL address in browser
    // console.log('catId: ', catId)
    // metric.id or pedigree.id from to={{ pathname: `./metric/${metric?.id}` }}
    // const { id: entityId } = useParams(); // we have params with entity_id (metric or pedigree) only in edit mode

    // const [values, setValues] = useState({
    //     id: null,
    //     num: '',
    //     issued_by: '',
    //     img_url: '',
    //     system_id: '',
    //     entityId
    // });

    // const [selectError, setSelectError] = useState({
    //     fileError: "",
    //     systemError: ""
    // });

    // const [currentSystem, setCurrentSystem] = useState('');
    // const [systems, setSystems] = useState([]);
    // const [selectedFile, setSelectedFile] = useState(null);
    // const [isImageSaved, setIsImageSaved] = useState("");

    const BASE_URL = process.env.REACT_APP_API_URL; //need only for UploadFile - latter maybe better to refactor

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch cat systems (common to both metric and pedigree)
                const res = await fetchCatSys();
                const dataForSelect = formatValuesForSelect(res, 'name', 'abb');

                // Fetch specific entity data based on whether it's a metric or pedigree
                if (isEdit) {
                    let resData;
                    if (entityType === 'pedigree') {
                        resData = await fetchOnePedigree(catId, currentUserId);
                        // console.log('resData for pedigree: ', resData);
                    } else if (entityType === 'metric') {
                        resData = await fetchOneMetric(catId, currentUserId);
                    }

                    if (resData) {
                        // console.log('resData: ', resData)
                        const filtered = dataForSelect.filter(sys => sys.value === resData.cat_systems_id)
                        // console.log('filtered', filtered)

                        // Dispatch all input changes at once
                        dispatch({
                            type: 'FETCH_SUCCESS',
                            payload: {
                                num: entityType === 'pedigree' ? resData.pedigree_num : resData.metric_num,
                                issued_by: resData.issued_by,
                                system_id: resData.cat_systems_id,
                                img_url: entityType === 'pedigree' ? resData.pedigree_img_url : resData.metric_img_url,
                                catSystems: dataForSelect,
                                currentSystem: filtered,
                                catId: catId,
                                id: resData.id //metric or pedigree id
                            }
                        });
                        // dispatch({ type: 'SET_FILE_URL', payload: entityType === 'pedigree' ? resData.pedigree_img_url : resData.metric_img_url });
                        // dispatch({ type: 'CHANGE_SELECT_SYSTEM', payload: resData.cat_systems_id });
                    }
                    // dispatch({ type: 'FETCHED_CAT_SYSTEMS', payload: dataForSelect });

                } else {
                    dispatch({
                        type: 'FETCHED_CAT_SYSTEMS',
                        payload: {
                            catSystems: dataForSelect,
                            catId: catId
                        }
                    });
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [catId, currentUserId, isEdit, entityType]);


    const handleChangeFile = (file) => {
        dispatch({ type: 'CHANGE_FILE', payload: file });
    };



    // const uploadToWrap2 = async () => {
    //     try {
    //         const fileP = await HandleUpload(state.selectedFile, `${BASE_URL}/upload`, currentUserId, entityType, state.values.cat_id);
    //         console.log('fileP.filePath: ', fileP.filePath)
    //         if (fileP) {
    //             dispatch({ type: 'SET_FILE_URL', payload: fileP.filePath });
    //             return true;
    //         }
    //     } catch (error) {
    //         dispatch({ type: 'ERROR', payload: 'Error uploading file' });
    //         return false;
    //     }
    // };
    // let fileP
    const uploadToWrap2 = async () => {
        try {
            const fileP = await HandleUpload(state.selectedFile, `${BASE_URL}/upload`, currentUserId, entityType, state.values.cat_id);
            console.log('fileP.filePath: ', fileP.filePath);

            if (fileP && fileP.filePath) {
                // Dispatch the file URL
                dispatch({ type: 'SET_FILE_URL', payload: fileP.filePath });
                if (state.values.img_url === fileP.filePath) {
                    // console.log('Image URL has been updated:', state.values.img_url);
                    // Proceed with the form submission once img_url is updated
                    return true; // Return immediately, don't wait for the state to update 
                }

            } else {
                dispatch({ type: 'ERROR', payload: 'Error: No file path returned' });
                return false;
            }
        } catch (error) {
            dispatch({ type: 'ERROR', payload: 'Error uploading file' });
            return false;
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate the system selection
        if (!state.currentSystem) {
            dispatch({ type: 'SET_SYSTEM_ERROR', payload: 'Please select a valid system.' });
            return;
        }

        // Validate the file selection
        if (!state.selectedFile) {
            dispatch({ type: 'SET_FILE_ERROR', payload: 'Please upload a valid file.' });
            return;
        }

        // Validate other form fields
        if (!state.currentSystem || !state.selectedFile || !state.values.num || !state.values.issued_by) {
            return;
        }

        const fileUploaded = await uploadToWrap2();
        // console.log('fileUploaded:', fileUploaded); // Check if file upload was successful
        if (fileUploaded) {
            console.log('Waiting for img_url update...');
            // some()
            finalSubmit3()
        }
    };

    // const finalSubmit3 = async () => {
    //     try {
    //         if (isEdit) {
    //             if (entityType === 'pedigree') {
    //                 await putPedigree(entityId, state.values); // Update pedigree
    //             } else if (entityType === 'metric') {
    //                 console.log('finalSubmit | before sending to Backend | state.values: ', state.values)
    //                 await putMetric(state.values); // Update metric
    //             }
    //         } else {
    //             if (entityType === 'pedigree') {
    //                 await postPedigree(state.values); // Create new pedigree
    //             } else if (entityType === 'metric') {
    //                 await postMetric(state.values); // Create new metric
    //             }
    //         }
    //         navigate(`/cats/${catId}`);
    //     } catch (err) {
    //         console.log('Error submitting form:', err);
    //         dispatch({ type: 'ERROR', payload: 'Error submitting form' });
    //     }
    // };

    // useEffect(() => {
    //     finalSubmit3(); // Call your final submission function here

    //     // if (state.values.img_url) {
    //     //     console.log('Image URL has been updated:', state.values.img_url);
    //     //     // Proceed with the form submission once img_url is updated
    //     //     finalSubmit3(); // Call your final submission function here
    //     // }
    // }, [state.isImageSaved, finalSubmit3]);

    const finalSubmit3 = useCallback(async () => {
        // console.log('finalSubmit3 evoked!')
        try {
            if (isEdit) {
                if (entityType === 'pedigree') {
                    await putPedigree(state.values); // Update pedigree
                } else if (entityType === 'metric') {
                    // console.log('finalSubmit | before sending to Backend | state.values: ', state.values);
                    await putMetric(state.values); // Update metric
                }
            } else {
                if (entityType === 'pedigree') {
                    await postPedigree(state.values); // Create new pedigree
                } else if (entityType === 'metric') {
                    await postMetric(state.values); // Create new metric
                }
            }
            navigate(`/cats/${catId}`);
        } catch (err) {
            console.log('Error submitting form:', err);
            dispatch({ type: 'ERROR', payload: 'Error submitting form' });
        }
    }, [isEdit, entityType, state.values, navigate, catId]); // Dependencies

    // Using useEffect to trigger finalSubmit3
    useEffect(() => {
        if (state.isImageSaved) {
            finalSubmit3(); // Call finalSubmit3 once the image is saved
        }
    }, [state.isImageSaved, finalSubmit3]); // Add finalSubmit3 as a dependency

    // function some() {
    //     if (state.values.img_url === fileP.filePath) {
    //         console.log('Image URL has been updated:', state.values.img_url);
    //         // Proceed with the form submission once img_url is updated
    //         finalSubmit3(); // Call your final submission function here
    //     }
    // }

    const handleChange = (e) => {
        dispatch({ type: 'CHANGE_INPUT', payload: { name: e.target.name, value: e.target.value } });
    };

    // const handleSystemChange = (selectedOption) => {
    //     setCurrentSystem(selectedOption);
    //     setValues(prev => ({ ...prev, system_id: selectedOption.value }));
    //     setSelectError(prev => ({ ...prev, systemError: '' }));
    // };
    const handleSystemChange = (selectedOption) => {
        if (selectedOption) {
            // Set the current system and reset any existing system error
            dispatch({ type: 'CHANGE_SELECT_SYSTEM', payload: selectedOption });
        } else {
            // If no valid option is selected, set a system error message
            dispatch({ type: 'SET_SYSTEM_ERROR', payload: 'Please select a valid system.' });
        }
    };

    const cancelEdit = () => {
        navigate(`/cats/${catId}`);
    };

    const inputs = [
        {
            id: "num",
            name: "num",
            type: "text",
            placeholder: `${entityType} number`,
            errorMessage: `Please enter a valid ${entityType} number`,
            label: `${entityType?.charAt(0).toUpperCase() + entityType?.slice(1)} Number*`,
            required: true,
            maxLength: 100,
            onChange: handleChange,
            value: state.values.num || ""
        },
        {
            id: "issued_by",
            name: "issued_by",
            type: "text",
            placeholder: `Issued by (Club or System)*`,
            label: `Issued by (Club or System)*`,
            maxLength: 50,
            required: false,
            onChange: handleChange,
            value: state.values.issued_by || ""
        },
        {
            id: "system_id",
            name: "system_id",
            type: "select",
            placeholder: "Select system",
            errorMessage: "System can't be empty",
            label: "System*",
            required: true,
            onChange: handleSystemChange,
            options: state.catSystems,
            value: state.currentSystem || ""
        }
    ];

    return (
        <div className="edit-create__wrap">
            <form className="edit-create__form" onSubmit={handleSubmit}>
                <h1>{isEdit ? `Edit ${entityType} - cat id:${state?.values.cat_id}` : `Create ${entityType}`}</h1>
                {inputs.map(input => (
                    <FormInput
                        key={input.id}
                        {...input}
                    />
                ))}
                {state.values.img_url && (
                    <figure className="edit-create__img">
                        <figcaption>Current Image</figcaption>
                        <img src={state.values.img_url} alt={`${entityType}`} />
                    </figure>
                )}
                {state.selectError.systemError && <div className="error">{state.selectError.systemError}</div>}
                <UploadFile selectedFile={state.selectedFile} onChangeFile={handleChangeFile} />
                <strong>{state.isImageSaved}</strong>
                <button type="submit" className="joinButton">Submit</button>
                <button type="button" className="button-light" onClick={cancelEdit}>Cancel</button>
                {state.selectError.fileError && <div className="error">{state.selectError.fileError}</div>}
            </form>
        </div>
    );
};

export default EditCreateForm;
