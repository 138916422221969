
export const INITIAL_STATE = {
    values: {
        id: null,
        num: '', // For both pedigree_num and metric_num
        issued_by: '', // For both pedigree_issued_by and metric_issued_by
        img_url: '', // For both pedigree_img_url and metric_img_url
        system_id: '',
        cat_id: null
    },
    catSystems: [],
    currentSystem: '',
    err: '',
    selectedFile: null,
    filePa: null,
    isImageSaved: false,
    selectError: {
        fileError: "",
        systemError: ""
    }
}

export const formReducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_SUCCESS':
            return {
                ...state,
                values: {
                    id: action.payload.id || state.values.id,
                    num: action.payload.num || state.values.num,
                    issued_by: action.payload.issued_by || state.values.issued_by,
                    img_url: action.payload.img_url || state.values.img_url,
                    system_id: action.payload.system_id || state.values.system_id,
                    cat_id: action.payload.catId || state.values.catId
                },
                // Separate properties outside of `values`
                catSystems: action.payload.catSystems || state.catSystems,
                currentSystem: action.payload.currentSystem || state.currentSystem,
                err: '' // Reset error on success
            };
        case "FETCHED_CAT_SYSTEMS":
            return {
                ...state,
                values: {
                    cat_id: action.payload.catId || state.values.catId
                },
                catSystems: action.payload.catSystems,
            };
        case "CHANGE_INPUT":
            return {
                ...state,
                values: {
                    ...state.values,
                    [action.payload.name]: action.payload.value
                }
            };
        case "CHANGE_SELECT_SYSTEM":
            return {
                ...state,
                currentSystem: action.payload,
                values: {
                    ...state.values,
                    system_id: action.payload.value,
                },
                selectError: {
                    ...state.selectError,
                    systemError: "" // Reset system error on valid selection
                }
            };
        case "SET_FILE_ERROR":
            return {
                ...state,
                selectError: {
                    ...state.selectError,
                    fileError: action.payload
                }
            };
        case "SET_SYSTEM_ERROR":
            return {
                ...state,
                selectError: {
                    ...state.selectError,
                    systemError: action.payload
                }
            };
        case "CHANGE_FILE":
            return {
                ...state,
                selectedFile: action.payload,
                err: "",
            };
        case "SET_FILE_URL":
            const newState = {
                ...state,
                filePa: action.payload, // Updates the file path
                values: {
                    ...state.values,
                    img_url: action.payload, // Sets the img_url in values to the file URL
                },
                isImageSaved: true,
                selectError: {
                    ...state.selectError,
                    fileError: "" // Resets file error on successful file upload
                }
            };
            console.log('Updated state:', newState);
            return newState;
        case "ERROR_RESET":
            return {
                ...state,
                err: "",
                selectError: {
                    fileError: "",
                    systemError: ""
                }
            };
        case "ERROR":
            return {
                ...state,
                err: action.payload,
            }
        default:
            return state;
    }
}

//Was before with useState:
// const [values, setValues] = useState(
//   {
//     // id: null,
//     num: '',
//     issued_by: '',
//     pedigree_img_url: '',
//     cat_systems_id: '',
//     catId
//   }
// );

// const [isCreating, setIsCreating] = useState(true); // determine if we're creating a new entity or updating an existing one
// const [selectError, setSelectError] = useState(false);
// const [currentSystem, setCurrentSystem] = useState('')
// const [err, setError] = useState(null);
// const [catSystems, setCatSystems] = useState([]);
// const [selectedFile, setSelectedFile] = useState(null);