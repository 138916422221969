import { useState } from "react";
import Select from 'react-select';
import "./formInput.scss"
// import { DialogModal } from '../../pages/CatProfile/PedigreeTab/DialogModal';

const FormInput = (props) => {
    // console.log('FormInput | props = ', props)
    const [focused, setFocused] = useState(false);
    const {
        id,
        name,
        type,
        label,
        placeholder,
        value = "", // Default to an empty string if value is undefined
        onChange,
        options,
        errorMessage,
        required,
        className,
        maxLength,
        isInvalid, additional, ...rest } = props;
    // label = "Username" or ...
    // we take key from Odject props and assign them their value.
    //unorderd
    const handleFocus = (e) => {
        setFocused(true);
    }
    // useRef()
    if ((props.type === "textarea")) {
        return (
            <div className="form-input">
                <label className="form-input__label" htmlFor={id}>{label}</label>
                <textarea
                    id={id}
                    className="form-input__input textarea" 
                    value={value || ""} // Ensure value is never undefined. Default to an empty string
                    onChange={onChange}
                    placeholder={placeholder}
                    onBlur={handleFocus}
                    required={required}
                    // onFocus={() => { inputProps.name === "confirmPassword" && setFocused(true) }}
                    focused={focused.toString()}
                    name={name}
                    aria-required={required}
                    maxLength={maxLength}
                    {...rest}
                />
                {/* <span className="joinForm__error">{errorMessage}</span> */}
            </div>
        )
    }
    else if (props.type === "radio") {
        // console.log('props in radio', props)
        return (
            <div className="form-input">
                <legend className="form-input__label">{label}</legend>
                <div className="form-radio-button">
                    <div className="form-radio-button__item">
                        <label htmlFor="male" className="form-radio-button__label">
                            <input
                                id="male"
                                // {...inputProps}
                                type={type}
                                name={name}
                                value="male"
                                onChange={onChange}
                                aria-label="Male"
                                checked={props.value === "male"}
                            />
                            <p>Male</p>
                        </label>
                    </div>
                    <div
                        className="form-radio-button__item">
                        <label htmlFor="female" className="form-radio-button__label">
                            <input
                                id="female"
                                // {...inputProps}
                                type={type}
                                value="female"
                                name={name}
                                onChange={onChange}
                                aria-label="Female"
                                checked={props.value === "female"}
                            />
                            <p>Female</p>
                        </label>
                    </div>
                </div>
            </div>

        )
    } else if (props.type === 'select') {
        // console.log('FormInput currentValue = ',  currentValue)
        return (
            <div className="form-input">
                <label className="form-input__label" >{label}
                    {/* // `value` should be an object like { value: 'country_id', label: 'Country Name' } */}
                    <Select
                        className='form-input__select'
                        id={id}
                        type={type}
                        name={name}
                        // value={value} //should be an object. currentValue should be { value: '1', label: 'Option 1' }
                        value={value || { label: "Select...", value: "" }} // Fallback to a valid empty object
                        options={options}
                        onChange={onChange}
                        required={required}
                    />
                </label>
                {/* {isEmpty && (
                    <span className="form-input__error">{errorMessage}</span>
                )} */}
            </div>
        )
    }
    else {
        // console.log('FormInput errorMessage = ', errorMessage);
        return (
            <div className="form-input">
                <label className="form-input__label" htmlFor={id}>{label}</label>
                <input
                    className="form-input__input"
                    id={id}
                    // {...inputProps}
                    type={type}
                    name={name}
                    value={value || ""} // Ensure value is never undefined
                    onChange={onChange}
                    placeholder={placeholder}
                    required={required}
                />
                <div>
                    <small>{additional}</small>
                </div>
                {isInvalid && (
                    <span className="form-input__error">{errorMessage}</span>
                )}
            </div>
        )
    }
}

// FormInput.propTypes = {
//     id: PropTypes.string.isRequired,
//     name: PropTypes.string.isRequired,
//     type: PropTypes.string.isRequired,
//     label: PropTypes.string.isRequired,
//     placeholder: PropTypes.string,
//     value: PropTypes.string.isRequired,
//     onChange: PropTypes.func.isRequired,
//     options: PropTypes.array,
//     errorMessage: PropTypes.string,
//     required: PropTypes.bool,
//   };

export default FormInput
